import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';
import { getCompanyList } from '../../../reducers/CompanyReducer';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../../styles/formStyles';
import { schema, initialFormValues, getPayload, industyCategories, industryList } from './utils';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment
} from '@mui/material';
import { submitEquipmentRegisterForm, getEquipmentById, getEquipmentList, editEquipment } from '../../../reducers/EquipmentReducer';
import validate from 'validate.js';
import { getUserList, setLoggedInUserData } from '../../../reducers/UserReducer';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { withStyles, makeStyles } from "@mui/styles";
import InputFileUpload from './uploadButton';

import getTranslationObject from "../../../utils/getTranslations";

validate.validators.IsValidURL = function (value) {
  var regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Company Web Site Is Not Valid";
  }
  return null
};

const useStyles = makeStyles((theme) => ({
  reservePrice: {
    paddingTop: '20px'
  },
}));


const CreateEquipment = props => {
  const { restClient: { rest }, equipmentData, companyList, userList, equipmentList, getEquipmentById, getCompanyList, getEquipmentList, getUserList, loggedInUserData, userData, locale } = props;
  const classes = useStyles();
  const query = useQuery();
  const action = query.get('action');
  const equipmentID = query.get('id');
  const [filterUserBranch, setFilterUserBranch] = useState([]);
  const [selectParentCompany, setSelectParentCompany] = useState({});
  // initialFormValues.company = selectParentCompany;
  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });

  const i18n = getTranslationObject(locale, "create_equipment_page");
  const translatedIndustryList = i18n.getIndustryList();
  const translatedCategoryList = i18n.getCategoryList(formState.values.industry);

  const [reservePrice, setReservePrice] = useState('');
  const [listingPrice, setListingPrice] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [files, setFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 1000,
    sort: null,
    searchData: '',
  });

  const history = useHistory();

  const [showSerialNumber, setShowSerialNumber] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };
  const toggleSerialNumberVisibility = () => {
    setShowSerialNumber(!showSerialNumber);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    // Go back to the previous page
    history.goBack();
  };

  const handleFirstInputChange = (event) => {
    const newValue = event.target.value;
    setReservePrice(newValue);
    validateInput(newValue, listingPrice);
  };

  const handleSecondInputChange = (event) => {
    const newValue = event.target.value;
    setListingPrice(newValue);
    validateInput(reservePrice, newValue);
  };

  const validateInput = (first, second) => {
    const isFirstValid = !isNaN(first) && first !== '';
    const isSecondValid = !isNaN(second) && second !== '';

    if (isFirstValid && isSecondValid) {
      setIsValid(parseFloat(first) < parseFloat(second));
    } else {
      setIsValid(true); // Reset validation if either field is empty or not a number
    }
  };

  // console.log("userData",userData);
  // console.log("userList",userList);
  // console.log("equipmentData", equipmentData);
  // const [filterUserBranch, setFilterUserBranch] = useState([])

  useEffect(() => {
    const getList = async () => {
      if (rest && loggedInUserData) {
        await getEquipmentList(pageState, loggedInUserData);
      }
    };
    getList();
  }, [rest, getEquipmentList, loggedInUserData, pageState]);

  // console.log("equipmentList", equipmentList);
  // console.log("userList", userList);

  useEffect(() => {
    const getBranches = async () => {
      if (userData?.user && userData?.user.roleSystem === "regular") {
        const branchSel = userData.user.parentCompany.branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
        setFilterUserBranch(branchSel)
      }
    };
    getBranches();
  }, [rest, userData]);

  const handleBranchChange = (event) => {
    // event.persist();
    const companySel = event.target.value;
    const branchSel = userList.filter((un) => un.id === companySel)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
    const selectCompany = userList.filter((un) => un.id === companySel)[0].parentCompany;
    setSelectParentCompany(selectCompany);
    setFilterUserBranch(branchSel);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const userVal = loggedInUserData?.user?.roleSystem === 'admin';
  // console.log("loggedInUserData", loggedInUserData);

  const [branches, setBranches] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [majorCategory, setMajorCategory] = useState([]);
  // const [industry, setIndustry] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  const sanitizeUrl = (url) => {
    return url.replace(/^https?:\/\//, '');
  };

  const handleChange = (event) => {
    // event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'file'
            ? event.target.files[0].name
            : sanitizeUrl(event.target.value)
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  const handleStartDateChange = (name, date) => {
    handleStateChange(name, date);
  }

  const handleIndustryChange = (event) => {
    // event.persist();
    const selectedIndustry = event.target.value;
    const majorCategory = industyCategories.filter((un) => un.industry === selectedIndustry).map((un) => un.majorCategory).flat(1);
    // setIndustry(selectedIndustry);
    setMajorCategory(majorCategory);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  useEffect(() => {
    const getMajorCategory = async () => {
      try {
        let arr = [];
        if (action === 'edit' && equipmentID && equipmentData && rest) {
          arr = equipmentData.industry.toString();
          const majorCategory = industyCategories.filter((un) => un.industry === arr);
          setMajorCategory(majorCategory[0].majorCategory);
          setFiles(equipmentData?.pictures);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getMajorCategory();
  }, [action, equipmentID, equipmentData, rest]);

  const handleCompanyChange = (event) => {
    // event.persist();
    const companySel = event.target.value;
    const branchSel = companyList.filter((un) => un.id === companySel)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
    // setSelectedCompany(companySel);
    setBranches(branchSel);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value
      },
      touched: {
        ...prevFormState.touched,
        [field]: true
      }
    }));
  };
  useEffect(() => {
    if (action === 'edit' && equipmentID && rest) {
      const getEquipment = async () => {
        getEquipmentById(equipmentID);
      }
      getEquipment();
    }
  }, [action, getEquipmentById, equipmentID, rest])

  useEffect(() => {
    if (rest) {
      const getList = async () => {
        getCompanyList();
        getUserList();
      }
      getList();
    }
  }, [rest, getCompanyList, getUserList])

  useEffect(() => {
    if (action === 'edit' && equipmentData) {
      setFormState({
        isValid: false,
        values: equipmentData,
        touched: {},
        errors: {},
      });
      setReservePrice(equipmentData.reservePrice);
      setListingPrice(equipmentData.listingPrice);
      setSelectParentCompany(equipmentData.company);
    }
  }, [action, equipmentData]);

  useEffect(() => {
    const getBranch = async () => {
      try {
        let branches = [];
        if (action === 'edit' && equipmentID && rest && equipmentData && userData && userList) {
          if (equipmentData.branchID && userData?.user && userData?.user?.roleSystem === "admin") {
            branches = userList.filter((c) => c.id === equipmentData.createdBy && c.branch.some((i) => i.branchID === equipmentData.branchID)).map((o) => o.branch[0]);
            setFilterUserBranch(branches);
          }
          else {
            branches = userList.filter((un) => un.id === equipmentData.createdBy)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
            setFilterUserBranch(branches);
          }
          if (equipmentData.availabilityStatus.length > 0) {
            setSelectedOption("available");
          } else if (equipmentData.needStatus.length > 0) {
            setSelectedOption("need");
          } else {
            setSelectedOption("");
          }
        }
      } catch (e) {
        // console.log(e);
      }
    };
    if (userList && equipmentData) {
      getBranch();
    }
  }, [action, userList, rest, userData, equipmentData, equipmentID]);


  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  }

  const StartCase = (text) => {
    return text
      .split(" ")
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };
  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }

    if (!userVal) {
      delete schema.createdBy;
    }
    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));
    return isErrorExist;
  }

  const submitForm = event => {
    event.preventDefault();
    // let availableEquipList = (equipmentList || []).filter((x) => x.availabilityStatus === "Ready");
    // let needEquipList = (equipmentList || []).filter((x) => x.needStatus === "Need");

    const convertToAvailabilityFormat = (inputDate) => {
      // Convert the input date to ISO 8601 format
      return inputDate.toISOString();
    };
    const now = new Date(); // Current date
    const formattedDate = convertToAvailabilityFormat(now);

    let matchedAvailableDates = (equipmentList || [])?.filter((un) => un.availabilityStatus === "Ready" && formState?.values?.needEndDate !== "" && un.make === formState?.values?.make &&
      un.model === formState?.values?.model &&
      un.modelYear === Number(formState?.values?.modelYear) &&
      (un.availabilityStartDate > formState?.values?.needEndDate) && un.availabilityStartDate > formattedDate);

    let matchedAvailabilityStartDate = (equipmentList || [])
      ?.filter((un) => 
        un.availabilityStatus === "Ready" &&
        formState?.values?.needEndDate !== "" &&
        un.make === formState?.values?.make &&
        un.model === formState?.values?.model &&
        un.modelYear === Number(formState?.values?.modelYear) &&
        un.availabilityStartDate > formState?.values?.needEndDate &&
        un.availabilityStartDate > formattedDate
      )
      .map((un) => un.availabilityStartDate);

      console.log("matchedAvailabilityStartDate", matchedAvailabilityStartDate);

    let matchedAvailabilityEndDate = (equipmentList || [])?.filter((un) => un.availabilityStatus === "Ready" && un.make === formState?.values?.make &&
      un.model === formState?.values?.model &&
      un.modelYear === Number(formState?.values?.modelYear) &&
      (un.availabilityEndDate < formState?.values?.needStartDate) && formState?.values?.needStartDate > formattedDate)
      .map((un) => un.availabilityEndDate);

      console.log("matchedAvailabilityEndDate", matchedAvailabilityEndDate);
      
    let matchedNeedStartDate = (equipmentList || [])
      ?.filter((un) => 
        un.availabilityStatus === "Ready" &&
        un.make === formState?.values?.make &&
        un.model === formState?.values?.model &&
        un.modelYear === Number(formState?.values?.modelYear) &&
        un.availabilityEndDate < formState?.values?.needStartDate &&
        formState?.values?.needStartDate > formattedDate
      )
      .map(() => formState?.values?.needStartDate);

      console.log("matchedNeedStartDate", matchedNeedStartDate);

    let matchedNeedEndDate = (equipmentList || [])
      ?.filter((un) => 
        un.availabilityStatus === "Ready" &&
        formState?.values?.needEndDate !== "" &&
        un.make === formState?.values?.make &&
        un.model === formState?.values?.model &&
        un.modelYear === Number(formState?.values?.modelYear) &&
        un.availabilityStartDate > formState?.values?.needEndDate &&
        un.availabilityStartDate > formattedDate
      )
      .map(() => formState?.values?.needEndDate);

      console.log("matchedNeedEndDate", matchedNeedEndDate);

    let afterEmail = (userList || [])?.filter((x) => x.id === matchedAvailableDates[0]?.createdBy);

    let matchedNeedDates = (equipmentList || [])?.filter((un) => un.availabilityStatus === "Ready" && un.make === formState?.values?.make &&
      un.model === formState?.values?.model &&
      un.modelYear === Number(formState?.values?.modelYear) &&
      (un.availabilityEndDate < formState?.values?.needStartDate) && formState?.values?.needStartDate > formattedDate);

    let beforeEmail = (userList || [])?.filter((x) => x.id === formState?.values?.createdBy);
      
      
    if (!checkErrors()) {
      const { values } = formState;
      // const matchedAvailableDates = checkMatchedAvailableDates();
      // const matchedNeedDates = checkMatchedNeedDates();
      const payload = getPayload(values, userData, selectParentCompany);
      if (selectedOption === "need") {
        payload.assetState.availabilityStateTransition = []
        if (payload.assetState.needStateTransition[0] && payload.assetState.needStateTransition[0].needStatus.length === 0) {
          payload.assetState.needStateTransition = []
        }
      } else if (selectedOption === "available") {
        payload.assetState.needStateTransition = []
        if (payload.assetState.availabilityStateTransition[0] && payload.assetState.availabilityStateTransition[0].availabilityStatus.length === 0) {
          payload.assetState.availabilityStateTransition = []
        }
      } else if (selectedOption === "") {
        payload.assetState.needStateTransition = []
        payload.assetState.availabilityStateTransition = []
      }
      if (matchedAvailableDates.length > 0) {
        alert("Buyer Needs Equipment before your start date, are you ready to make your asset available now??");
        payload.notificationStatus = "After";
        payload.emailId = afterEmail[0].email;
        payload.availabilityStartDate = matchedAvailabilityStartDate[0];
        payload.needEndDate = matchedNeedEndDate[0];
      } else if (matchedNeedDates.length > 0) {
        alert("Available before you need");
        payload.notificationStatus = "Before";
        payload.emailId = beforeEmail[0].email;
        payload.availabilityEndDate = matchedAvailabilityEndDate[0];
        payload.needStartDate = matchedNeedStartDate[0];
      }
      if (action === 'edit' && equipmentID) {
        payload.reservePrice = reservePrice;
        payload.listingPrice = listingPrice;
        payload.pictures = files;
        props.editEquipment(equipmentID, payload);
      }
      else {
        payload.fleetType = 'Rental';
        payload.qtrProjected = 'Q1';
        payload.pricingModel = 'Negotiated';
        payload.assetState.locationStateTransition.locationStatus = 'Located';
        payload.assetState.usageStateTransition[0].usageStatus = 'InUse';
        payload.reservePrice = reservePrice;
        payload.listingPrice = listingPrice;
        payload.pictures = files;
        props.submitEquipmentRegisterForm(payload);
      }
    }
  }
  return (
    <Grid container spacing={5}>
      <Grid
        item
        xs={12}
        lg={12}>
        <Card >
          <Header>
            <HeaderText >
              <h5>{action === 'edit' ? i18n.translate("page_title_edit") : i18n.translate("page_title_create")}</h5>
            </HeaderText>
          </Header>
          <CardContent >
            <form className='formSpacing' onSubmit={submitForm}>
              <Grid container spacing={4}>
                <Grid item lg={6} xs={12}>
                  <FormControlWrapper>
                    <FormControl className='w-100'
                      error={hasError('equipmentName')}
                      helperText={
                        hasError('equipmentName') ? formState.errors.equipmentName[0] : null
                      }
                    >
                      <TextField
                        error={hasError('equipmentName')}
                        helperText={
                          hasError('equipmentName') ? formState.errors.equipmentName[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("equipment_name")}
                        data-test-id="Create-Equipment-EquipmentName"
                        fullWidth
                        id="companyEquipmentID"
                        placeholder={i18n.translate("equipment_name")}
                        type="text"
                        name="equipmentName"
                        value={formState.values.equipmentName}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('equipmentDescription')}
                      helperText={
                        hasError('equipmentDescription') ? formState.errors.equipmentDescription[0] : null
                      }
                    >
                      <TextField
                        error={hasError('equipmentDescription')}
                        helperText={
                          hasError('equipmentDescription') ? formState.errors.equipmentDescription[0] : null
                        }
                        variant="outlined"
                        multiline
                        label={i18n.translate("equipment_description")}
                        data-test-id="Create-Equipment-EquipmentDescription"
                        fullWidth
                        id="equipmentDescription"
                        placeholder={i18n.translate("equipment_description")}
                        type="text"
                        name="equipmentDescription"
                        value={formState.values.equipmentDescription}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('fleetType')}
                      helperText={
                        hasError('fleetType') ? formState.errors.fleetType[0] : null
                      }
                    >
                      <InputLabel id="create-company-country-select-outlined-label">{i18n.translate("fleet_type")}</InputLabel>
                      <Select
                        error={hasError('fleetType')}
                        helperText={
                          hasError('fleetType') ? formState.errors.fleetType[0] : null
                        }
                        label={i18n.translate("fleet_type")}
                        data-test-id="Create-Equipment-FleetType"
                        value={formState.values.fleetType}
                        onChange={handleChange}
                        inputProps={{
                          name: 'fleetType',
                          id: 'fleetType',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("fleet_type")}</MenuItem>
                        {[{ name: 'Rental' }, { name: 'Owned' }].map((fleetType, index) => <MenuItem value={fleetType.name}>{i18n.translate("fleet_type_options")[index]}</MenuItem>)}
                      </Select>
                      {hasError('fleetType') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('fleetType')}>
                          {formState.errors.fleetType[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>

                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('make')}
                      helperText={
                        hasError('make') ? formState.errors.make[0] : null
                      }
                    >
                      <TextField
                        error={hasError('make')}
                        helperText={
                          hasError('make') ? formState.errors.make[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("manufacturing_company")}
                        data-test-id="Create-Equipment-Make"
                        fullWidth
                        placeholder={i18n.translate("manufacturing_company")}
                        type="text"
                        name="make"
                        value={formState.values.make}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('industry')}
                      helperText={
                        hasError('industry') ? formState.errors.industry[0] : null
                      }
                    >
                      <InputLabel id="create-company-country-select-outlined-label">{i18n.translate("industry")}</InputLabel>
                      <Select
                        error={hasError('industry')}
                        helperText={
                          hasError('industry') ? formState.errors.industry[0] : null
                        }
                        label={i18n.translate("industry")}
                        data-test-id="Create-Equipment-industry"
                        value={formState.values.industry}
                        onChange={handleIndustryChange}
                        inputProps={{
                          name: 'industry',
                          id: 'industry',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("select_industry")}</MenuItem>
                        {(industryList || []).map((industry, index) => <MenuItem value={industry}>{translatedIndustryList[index]}</MenuItem>)}
                      </Select>
                      {hasError('industry') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('industry')}>
                          {formState.errors.industry[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>

                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('majorCategory')}
                      helperText={
                        hasError('majorCategory') ? formState.errors.majorCategory[0] : null
                      }
                    >
                      <InputLabel id="create-company-country-select-outlined-label">{i18n.translate("major_category")}</InputLabel>
                      <Select
                        error={hasError('majorCategory')}
                        helperText={
                          hasError('majorCategory') ? formState.errors.majorCategory[0] : null
                        }
                        label={i18n.translate("major_category")}
                        data-test-id="Create-Equipment-majorCategory"
                        value={formState.values.majorCategory}
                        onChange={handleChange}
                        inputProps={{
                          name: 'majorCategory',
                          id: 'majorCategory',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("select_category")}</MenuItem>
                        {(majorCategory || []).map((category, index) => <MenuItem value={category}>{translatedCategoryList[index]}</MenuItem>)}
                      </Select>
                      {hasError('majorCategory') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('majorCategory')}>
                          {formState.errors.majorCategory[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('model')}
                      helperText={
                        hasError('model') ? formState.errors.model[0] : null
                      }
                    >
                      <TextField
                        error={hasError('model')}
                        helperText={
                          hasError('model') ? formState.errors.model[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("model")}
                        data-test-id="Create-Equipment-Model"
                        fullWidth
                        placeholder={i18n.translate("model")}
                        type="text"
                        name="model"
                        value={formState.values.model}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('modelYear')}
                      helperText={
                        hasError('modelYear') ? formState.errors.modelYear[0] : null
                      }
                    >
                      <TextField
                        error={hasError('modelYear')}
                        helperText={
                          hasError('modelYear') ? formState.errors.modelYear[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("model_year")}
                        data-test-id="Create-Equipment-modelYear"
                        fullWidth
                        placeholder={i18n.translate("model_year")}
                        type="text"
                        name="modelYear"
                        value={formState.values.modelYear}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('serialNum')}
                      helperText={
                        hasError('serialNum') ? formState.errors.serialNum[0] : null
                      }
                    >
                      <TextField
                        error={hasError('serialNum')}
                        helperText={
                          hasError('serialNum') ? formState.errors.serialNum[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("serial_number")}
                        data-test-id="Create-Equipment-SerailNum"
                        fullWidth
                        placeholder={i18n.translate("serial_number")}
                        type={showSerialNumber ? 'text' : 'password'}
                        name="serialNum"
                        value={formState.values.serialNum}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle serial number visibility"
                                onClick={toggleSerialNumberVisibility}
                                edge="end"
                              >
                                {showSerialNumber ? <VisibilityOff /> : <Visibility />}
                              </IconButton >
                            </InputAdornment >
                          )
                        }}
                      />
                    </FormControl >
                  </FormControlWrapper >
                  {
                    userVal ?
                      (<FormControlWrapper>
                        <FormControl variant="outlined" className='w-100'
                          error={hasError('createdBy')}
                          helperText={
                            hasError('createdBy') ? formState.errors.createdBy[0] : null
                          }
                        >
                          <InputLabel >{i18n.translate("creator_name")}</InputLabel>
                          <Select
                            error={hasError('createdBy')}
                            helperText={
                              hasError('createdBy') ? formState.errors.createdBy[0] : null
                            }
                            label={i18n.translate("creator_name")}
                            data-test-id="Create-Equipment-Company"
                            value={formState.values.createdBy}
                            onChange={handleBranchChange}
                            inputProps={{
                              name: 'createdBy',
                              id: 'createdBy',
                            }}
                          >
                            <MenuItem value="0" disabled>{i18n.translate("creator_name")}</MenuItem>
                            {(userList || []).map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                          </Select>
                          {hasError('createdBy') && (
                            <FormHelperText htmlFor="form-selector" error={hasError('createdBy')}>
                              {formState.errors.createdBy[0]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </FormControlWrapper >)
                      : null}
                  {/* <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('company')}
                                helperText={
                                  hasError('company') ? formState.errors.company[0] : null
                                }
                              >
                                <InputLabel >Select Company Name*</InputLabel>
                                <Select
                                  error={hasError('company')}
                                  helperText={
                                    hasError('company') ? formState.errors.company[0] : null
                                  }
                                  label="Select Company Name* "
                                  data-test-id="Create-Equipment-Company"
                                  value={formState.values.company}
                                  onChange={handleCompanyChange}
                                  inputProps={{
                                    name: 'company',
                                    id: 'company',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Company Name*</MenuItem>
                                  {(companyList || []).map((company) => <MenuItem value={company.id}>{company.companyName}</MenuItem>)}
                                </Select>
                                {hasError('company') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('company')}>
                                    {formState.errors.company[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper> */}
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('branchID')}
                      helperText={
                        hasError('branchID') ? formState.errors.branchID[0] : null
                      }
                    >
                      <InputLabel >{i18n.translate("company_branch")}</InputLabel>
                      <Select
                        error={hasError('branchID')}
                        helperText={
                          hasError('branchID') ? formState.errors.branchID[0] : null
                        }
                        label={i18n.translate("company_branch")}
                        data-test-id="Create-Equipment-BranchID"
                        value={formState.values.branchID}
                        onChange={handleChange}
                        inputProps={{
                          name: 'branchID',
                          id: 'branchID',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("company_branch")}</MenuItem>
                        {(filterUserBranch || []).map((item) => <MenuItem value={item.branchID}>{item.branchName}</MenuItem>)}
                      </Select>
                      {hasError('branchID') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('branchID')}>
                          {formState.errors.branchID[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <TextField
                      error={hasError('companyEquipmentID')}
                      helperText={
                        hasError('companyEquipmentID') ? formState.errors.companyEquipmentID[0] : null
                      }
                      variant="outlined"
                      label={i18n.translate("company_equip_id")}
                      fullWidth
                      placeholder={i18n.translate("company_equip_id")}
                      type="text"
                      data-test-id="Create-Equipment-CompanyEquipmentID"
                      name="companyEquipmentID"
                      id="companyEquipmentID"
                      value={formState.values.companyEquipmentID}
                      onChange={handleStartCaseChange}
                      onBlur={handleBlur}
                    />
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("meter_read")}
                        data-test-id="Create-Equipment-MeterRead"
                        fullWidth
                        placeholder={i18n.translate("meter_read")}
                        type="text"
                        name="meterRead"
                        autoComplete="new-password"
                        value={formState.values.meterRead}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('quantity')}
                      helperText={
                        hasError('quantity') ? formState.errors.quantity[0] : null
                      }
                    >
                      <TextField
                        error={hasError('quantity')}
                        helperText={
                          hasError('quantity') ? formState.errors.quantity : null
                        }
                        variant="outlined"
                        label={i18n.translate("quantity")}
                        data-test-id="Create-Equipment-Quantity"
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 50 } }}
                        placeholder={i18n.translate("quantity")}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="quantity"
                        value={formState.values.quantity}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100"
                      error={hasError('currentLocationCity')}
                      helperText={
                        hasError('currentLocationCity') ? formState.errors.currentLocationCity[0] : null
                      }
                    >
                      <TextField
                        error={hasError('currentLocationCity')}
                        helperText={
                          hasError('currentLocationCity') ? formState.errors.currentLocationCity[0] : null
                        }
                        variant="outlined"
                        label={i18n.translate("current_location_city")}
                        data-test-id="Create-Equipment-currentLocationCity"
                        fullWidth
                        placeholder={i18n.translate("current_location_city")}
                        type="text"
                        name="currentLocationCity"
                        value={formState.values.currentLocationCity}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                </Grid >
                <Grid item lg={6} xs={12}>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("latitude")}
                        data-test-id="Create-Equipment-locationLat"
                        fullWidth
                        placeholder={i18n.translate("latitude")}
                        type="text"
                        name="locationLat"
                        value={formState.values.locationLat}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("longitude")}
                        data-test-id="Create-Equipment-locationLong"
                        fullWidth
                        placeholder={i18n.translate("longitude")}
                        type="text"
                        name="locationLong"
                        value={formState.values.locationLong}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('pricingModel')}
                      helperText={
                        hasError('pricingModel') ? formState.errors.pricingModel[0] : null
                      }
                    >
                      <InputLabel id="create-company-country-select-outlined-label">{i18n.translate("pricing_model")}</InputLabel>
                      <Select
                        error={hasError('pricingModel')}
                        helperText={
                          hasError('pricingModel') ? formState.errors.pricingModel[0] : null
                        }
                        label={i18n.translate("pricing_model")}
                        data-test-id="Create-Equipment-PricingModel"
                        value={formState.values.pricingModel}
                        onChange={handleChange}
                        inputProps={{
                          name: 'pricingModel',
                          id: 'pricingModel',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("pricing_model")}</MenuItem>
                        {[{ name: 'Negotiated' }, { name: 'FixedPrice' }, { name: 'Bidding' }, { name: 'Free' }].map((pricingModel, index) => <MenuItem value={pricingModel.name}>{i18n.translate("pricing_model_options")[index]}</MenuItem>)}
                      </Select>
                      {hasError('pricingModel') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('pricingModel')}>
                          {formState.errors.pricingModel[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('locationStatus')}
                      helperText={
                        hasError('locationStatus') ? formState.errors.locationStatus[0] : null
                      }
                    >
                      <InputLabel id="create-Equipment-state-select-outlined-label">{i18n.translate("location_status")}</InputLabel>
                      <Select
                        error={hasError('locationStatus')}
                        helperText={
                          hasError('locationStatus') ? formState.errors.locationStatus[0] : null
                        }
                        label={i18n.translate("location_status")}
                        data-test-id="Create-Equipment-LocationStatus"
                        value={formState.values.locationStatus}
                        onChange={handleChange}
                        inputProps={{
                          name: 'locationStatus',
                          id: 'locationStatus',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("location_status")}</MenuItem>
                        {[{ name: 'Locating' }, { name: 'Located' }, { name: 'Moving' }].map((locationStatus, index) => <MenuItem value={locationStatus.name}>{i18n.translate("location_status_options")[index]}</MenuItem>)}
                      </Select>
                      {hasError('locationStatus') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('locationStatus')}>
                          {formState.errors.locationStatus[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('usageStatus')}
                      helperText={
                        hasError('usageStatus') ? formState.errors.usageStatus[0] : null
                      }
                    >
                      <InputLabel id="create-company-state-select-outlined-label">{i18n.translate("usage_status")}</InputLabel>
                      <Select
                        error={hasError('usageStatus')}
                        helperText={
                          hasError('usageStatus') ? formState.errors.usageStatus[0] : null
                        }
                        label={i18n.translate("usage_status")}
                        data-test-id="Create-Equipment-UsageState"
                        value={formState.values.usageStatus}
                        onChange={handleChange}
                        inputProps={{
                          name: 'usageStatus',
                          id: 'usageStatus',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("usage_status")}</MenuItem>
                        {[{ name: 'New' }, { name: 'InUse' }, { name: 'Waiting' }, { name: 'Parked' }, { name: 'Maintenance' }, { name: 'EOL' }].map((usageStatus, index) => <MenuItem value={usageStatus.name}>{i18n.translate("usage_status_options")[index]}</MenuItem>)}
                      </Select>
                      {hasError('usageStatus') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('usageStatus')}>
                          {formState.errors.usageStatus[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl variant="outlined" className='w-100'
                      error={hasError('qtrProjected')}
                      helperText={
                        hasError('qtrProjected') ? formState.errors.qtrProjected[0] : null
                      }
                    >
                      <InputLabel id="create-company-state-select-outlined-label">{i18n.translate("qtr_projected")}</InputLabel>
                      <Select
                        error={hasError('qtrProjected')}
                        label={i18n.translate("qtr_projected")}
                        data-test-id="Create-Equipment-qtrProjected"
                        value={formState.values.qtrProjected}
                        onChange={handleChange}
                        inputProps={{
                          name: 'qtrProjected',
                          id: 'qtrProjected',
                        }}
                      >
                        <MenuItem value="0" disabled>{i18n.translate("qtr_projected")}</MenuItem>
                        {[{ name: 'Q1' }, { name: 'Q2' }, { name: 'Q3' }, { name: 'Q4' }].map((qtrProjected) => <MenuItem value={qtrProjected.name}>{qtrProjected.name}</MenuItem>)}
                      </Select>
                      {hasError('qtrProjected') && (
                        <FormHelperText htmlFor="form-selector" error={hasError('qtrProjected')}>
                          {formState.errors.qtrProjected[0]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("photo_link")}
                        data-test-id="Create-Equipment-photoLink"
                        fullWidth
                        placeholder={i18n.translate("photo_link")}
                        type="text"
                        name="photoLink"
                        value={formState.values.photoLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("media_link")}
                        data-test-id="Create-Equipment-mediaLink"
                        fullWidth
                        placeholder={i18n.translate("media_link")}
                        type="text"
                        name="mediaLink"
                        value={formState.values.mediaLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        variant="outlined"
                        label={i18n.translate("pricing_tiers")}
                        data-test-id="Create-Equipment-pricingTiers"
                        fullWidth
                        placeholder={i18n.translate("pricing_tiers")}
                        type="text"
                        name="pricingTiers"
                        value={formState.values.pricingTiers}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <TextField
                        multiline
                        variant="outlined"
                        label={i18n.translate("asset_description")}
                        data-test-id="Create-Equipment-AssetDescription"
                        fullWidth
                        placeholder={i18n.translate("asset_description")}
                        type="text"
                        name="assetDescription"
                        value={formState.values.assetDescription}
                        onChange={handleStartCaseChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="radio-options"
                        name="radio-options"
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <FormControlLabel value="need" control={<Radio disabled={(action === "edit" && selectedOption === "available")} />} label={i18n.translate("need")} />
                        <FormControlLabel value="available" control={<Radio disabled={(action === "edit" && selectedOption === "need")} />} label={i18n.translate("available")} />
                      </RadioGroup>
                      {selectedOption === 'need' ? (
                        <div>
                          <FormControlWrapper>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label={i18n.translate("start_date")}
                                name="needStartDate"
                                minDate={new Date()}
                                value={formState.values.needStartDate}
                                onChange={(date) => handleStartDateChange('needStartDate', date.toISOString())}
                                renderInput={(params) => <TextField variant='outlined' ref={params.error = false} {...params} />}
                              />
                            </LocalizationProvider>
                          </FormControlWrapper>
                          <FormControlWrapper>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label={i18n.translate("end_date")}
                                name="needEndDate"
                                minDate={formState.values.needStartDate === '' ? new Date() : formState.values.needStartDate}
                                value={formState.values.needEndDate}
                                onChange={(date) => handleStartDateChange('needEndDate', date.toISOString())}
                                renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                              />
                            </LocalizationProvider>
                          </FormControlWrapper>
                          <FormControlWrapper>
                            <FormControl variant="outlined" className='w-100'
                            >
                              <InputLabel id="create-company-state-select-outlined-label">Need Status </InputLabel>
                              <Select
                                error={hasError('needStatus')}

                                label={i18n.translate("need_status")}

                                value={formState.values.needStatus}
                                onChange={handleChange}
                                inputProps={{
                                  name: 'needStatus',
                                  id: 'needStatus',
                                }}
                              >
                                <MenuItem value="0" disabled>{i18n.translate("need_status")}</MenuItem>
                                {[{ name: 'Needed' }, { name: 'Need' }, { name: 'Needing' }].map((needStatus) => <MenuItem value={needStatus.name}>{needStatus.name}</MenuItem>)}
                              </Select>
                              {hasError('needStatus') && (
                                <FormHelperText htmlFor="form-selector" error={hasError('needStatus')}>
                                  {formState.errors.needStatus[0]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </FormControlWrapper>
                        </div>
                      ) : (selectedOption === 'available') ? <div className={classes.reservePrice}>
                        <FormControlWrapper>
                          <FormControl className={classes.reservePrice}>
                            <TextField
                              variant="outlined"
                              label={i18n.translate("reserve_price")}
                              data-test-id="Create-Equipment-ReservePrice"
                              fullWidth
                              placeholder={i18n.translate("reserve_price")}
                              type="number"
                              name="reservePrice"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              value={reservePrice}
                              onChange={handleFirstInputChange}
                              onBlur={handleBlur}
                            />
                          </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <FormControl className="w-100">
                            <TextField
                              variant="outlined"
                              label={i18n.translate("comparable_retail_price")}
                              data-test-id="Create-Equipment-ComparableRetailPrice"
                              fullWidth
                              placeholder={i18n.translate("comparable_retail_price")}
                              type="number"
                              name="comparableRetailPrice"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              value={formState.values.comparableRetailPrice}
                              onChange={handleStartCaseChange}
                              onBlur={handleBlur}
                            />
                          </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <FormControl className="w-100"
                            error={hasError('listingPrice')}
                            helperText={
                              hasError('listingPrice') ? formState.errors.listingPrice[0] : null
                            }
                          >
                            <TextField
                              error={hasError('listingPrice')}
                              helperText={
                                hasError('listingPrice') ? formState.errors.listingPrice[0] : null
                              }
                              variant="outlined"
                              label={i18n.translate("listing_price")}
                              data-test-id="Create-Equipment-ListingPrice"
                              fullWidth
                              placeholder={i18n.translate("listing_price")}
                              type="number"
                              name="listingPrice"
                              value={listingPrice}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              onChange={handleSecondInputChange}
                              onBlur={handleBlur}
                            />
                            {isValid ? null : <p style={{ color: 'red' }}>{i18n.translate("listing_price_err")}</p>}
                          </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={i18n.translate("start_date")}
                              name="availabilityStartDate"
                              minDate={new Date()}
                              value={formState.values.availabilityStartDate}
                              onChange={(date) => handleStartDateChange('availabilityStartDate', date.toISOString())}
                              renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={i18n.translate("end_date")}
                              name="availabilityEndDate"
                              minDate={formState.values.availabilityStartDate === '' ? new Date() : formState.values.availabilityStartDate}
                              value={formState.values.availabilityEndDate}
                              onChange={(date) => handleStartDateChange('availabilityEndDate', date.toISOString())}
                              renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <FormControl variant="outlined" className='w-100'
                          >
                            <InputLabel id="create-company-state-select-outlined-label">{i18n.translate("availability_status")}</InputLabel>
                            <Select
                              error={hasError('availabilityStateTransitionAvailabilityStatus')}
                              label={i18n.translate("availability_status")}
                              value={formState.values.availabilityStatus}
                              onChange={handleChange}
                              inputProps={{
                                name: 'availabilityStatus',
                                id: 'availabilityStatus',
                              }}
                            >
                              <MenuItem value="0" disabled>{i18n.translate("availability_status")}</MenuItem>
                              {[{ name: 'Unavailable' }, { name: 'Ready' }, { name: 'Pre-Order' }].map((availabilityStatus) => <MenuItem value={availabilityStatus.name}>{availabilityStatus.name}</MenuItem>)}
                            </Select>
                            {hasError('availabilityStatus') && (
                              <FormHelperText htmlFor="form-selector" error={hasError('availabilityStatus')}>
                                {formState.errors.availabilityStatus[0]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </FormControlWrapper>
                      </div> : null}
                    </FormControl>
                  </FormControlWrapper>
                  <FormControlWrapper>
                    <FormControl className="w-100">
                      <InputFileUpload
                        files={files}
                        setFiles={setFiles}
                        action={action}
                        equipmentID={equipmentID}
                        setFilesUploaded={setFilesUploaded}
                        locale={locale}
                      />
                    </FormControl>
                  </FormControlWrapper>
                </Grid >
              </Grid >
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                  <FormButtonWrapper>
                    {/* <Link to="/equipment" className='linkStyle'> */}
                    <Button
                      type="cancel"
                      color="secondary"
                      data-test-id="Create-Equipment-Cancel"
                      variant="contained"
                      size="large"
                      onClick={handleCancel}>
                      {i18n.translate("cancel")}
                    </Button>
                    {/* </Link> */}
                  </FormButtonWrapper>
                </Grid>
                <Grid item>
                  <FormButtonWrapper>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      data-test-id="Create-Equipment-Submit"
                      size="large"
                      className='Buttons'>
                      {action === 'edit' ? i18n.translate("update") : i18n.translate("submit")}
                    </Button>
                  </FormButtonWrapper>
                </Grid>
              </Grid>
            </form >
          </CardContent >
        </Card >
      </Grid >
    </Grid >
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  equipmentData: state.equipment.equipmentData,
  companyList: state.company.companyList.companyList,
  userList: state.user && state.user.userList.userList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  userData: state.user.userData,
  locale: state.locale,
  equipmentList: state.equipment && state?.equipment?.equipmentList?.equipmentList
});
export default connect(mapStateToProps, { submitEquipmentRegisterForm, getEquipmentById, editEquipment, getCompanyList, getUserList, setLoggedInUserData, getEquipmentList })(CreateEquipment);