import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitEquipmentRegisterForm,
  getEquipmentList,
  getEquipmentById,
  deleteEquipment,
} from "../../../reducers/EquipmentReducer";
import { GetUploadPayload } from "./utils";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  TextField,
  MenuItem,
  Tooltip,
  Button,
  useMediaQuery,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Sample_equipment_file from "../../../utils/Sample_equipment_file.xlsx";
import { getUserList, setLoggedInUserData } from '../../../reducers/UserReducer';
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import { industryList, industyCategories } from '../CreateEquipment/utils';
import { uploadImage } from "../../DealChat/blobStorage";
import getTranslationObject from "../../../utils/getTranslations";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/system';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-container--top [role=row]": {
            background: '#3E5272',
            color: "white"
          },
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "730px",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  textInput: {
    paddingLeft: "5px",
    marginBottom: "10px"
  },
  dropDiv: {
    display: "flex",
    marginLeft: "-8rem"
  },
  dropDiv2: {
    paddingLeft: "70px"
  },
});

function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start', // Aligns the icon to the left
  alignItems: 'center',
}));
const EquipmentList = (props) => {
  const {
    equipmentList = [],
    restClient: { rest },
    getEquipmentList,
    getEquipmentById,
    companyList = [],
    getCompanyList,
    loggedInUserData,
    userData,
    getUserList,
    userList,
    totalCount,
    locale
  } = props;

  const i18n = getTranslationObject(locale, "equipment_page");
  const tIndustryList = i18n.getIndustryList();

  const history = useHistory();
  const classes = useStyles();
  const [file, setFile] = useState();
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });

  // console.log("equipmentList", equipmentList);
  // console.log("loggedInUserData", loggedInUserData);
  // console.log("userData", userData);

  const [rowCountState, setRowCountState] = React.useState(totalCount);
  // const [filteredEquipmentDataList, setFilteredEquipmentDataList] = useState([]);
  const [search, setSearch] = React.useState('');
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [companyValue, setCompanyValue] = useState(null);
  const [industryValue, setIndustryValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState('');
  const [filteredEquipmentList, setFilteredEquipmentList] = useState(equipmentList);
  const [files, setFiles] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionToggle = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  // useEffect(() => {
  //   if (equipmentList.length > 0) {
  //     setFilteredEquipmentList(equipmentList);
  //   }
  // }, [equipmentList])
  useEffect(() => {
    const getList = async () => {
      if (rest && loggedInUserData) {
        await getEquipmentList(pageState, loggedInUserData);
        await getCompanyList(pageState);
      }
    };
    getList();
  }, [pageState, rest, loggedInUserData, getEquipmentList, getCompanyList]);

  useEffect(() => {
    if (rest) {
      const getList = async () => {
        getUserList();
      }
      getList();
    }
  }, [rest, getUserList])

  useEffect(() => {
    if (equipmentList.length > 0) {
      setRowCountState((prevRowCountState) =>
        totalCount !== undefined ? totalCount : prevRowCountState
      );
      setFilteredEquipmentList(equipmentList);
    }
  }, [totalCount, equipmentList]);

  useEffect(() => {
    if (search) {
      const filteredList = equipmentList?.filter(user => {
        const modelYearString = user.modelYear ? user.modelYear.toString() : '';
        const comparableRetailPriceString = user.comparableRetailPrice ? user.comparableRetailPrice.toString() : '';
        return (
          (user.equipmentName && user.equipmentName.toLowerCase().includes(search.toLowerCase())) ||
          (user.make && user.make.toLowerCase().includes(search.toLowerCase())) ||
          (user.model && user.model.toLowerCase().includes(search.toLowerCase())) ||
          (modelYearString && modelYearString.includes(search.toLowerCase())) ||
          (user.majorCategory && user.majorCategory.toLowerCase().includes(search.toLowerCase())) ||
          (user.mediaLink && user.mediaLink.toLowerCase().includes(search.toLowerCase())) ||
          (comparableRetailPriceString && comparableRetailPriceString.includes(search.toLowerCase()))
        );
      });

      setFilteredEquipmentList(filteredList);
      // if(filteredList?.length >= 0){
      setRowCountState(filteredList.length);
      // }
    } else {
      setFilteredEquipmentList(equipmentList);
      // if(equipmentList?.length > 0){
      setRowCountState(totalCount);
      // }
    }
  }, [search, totalCount]);


  // useEffect(() => {
  //   const getList = async () => {
  //     if (rest && loggedInUserData) {
  //       await getEquipmentList(pageState, loggedInUserData);
  //       await getCompanyList(pageState);
  //     }
  //   };
  //   getList();
  // }, [rest, getEquipmentList, loggedInUserData, getCompanyList, pageState]);

  // useEffect(() => {
  //   if (rest) {
  //     const getList = async () => {
  //       getUserList();
  //     }
  //     getList();
  //   }
  // }, [rest, getUserList])

  // useEffect(() => {
  //   if (rest) {
  //     const getEquipmentCompany = async () => {
  //       getCompanyList();
  //     };
  //     getEquipmentCompany();
  //   }
  // }, [rest, getCompanyList]);

  // React.useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     totalCount !== undefined ? totalCount : prevRowCountState
  //   );
  // }, [totalCount, setRowCountState]);

  function handleChange(event) {
    setFile(event.target.files[0])
    setDisabled(false);
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }

  const handleEquipmentEdit = (event, equipment) => {
    history.push(`/equipment/create?action=edit&id=${equipment.id}`);
  };
  const handleEquipmentView = (event, equipment) => {
    history.push(`/view/equipment?action=ViewColumn&id=${equipment.id}`);
  };
  const handleEquipmentDelete = (event, equipment) => {
    props.deleteEquipment(equipment.id, equipmentList, totalCount);
  };

  const handleNeedAction = (event, equipment, needEndDatesAfter, needStartDatesBefore) => {
    event.stopPropagation();
    const isAfter = Boolean(needEndDatesAfter); // Check if needEndDatesAfter exists
    const isBefore = Boolean(needStartDatesBefore); // Check if needStartDatesBefore exists

    const notificationStatus = isAfter ? "After" : isBefore ? "Before" : null;

    if (!notificationStatus) {
      alert("No action available");
      return;
    }

    const alertsData = notificationStatus === "After" ? "Buyer Needs Equipment before your start date, are you ready to make your asset available now??" : "Available before you need"

    alert(alertsData);
    // console.log("equipment", equipment);
    // console.log("Dates", isAfter ? needEndDatesAfter : needStartDatesBefore);

    let equipmentName = equipment.equipmentName;
    let availabilityStartDate = equipment.availabilityStartDate;
    let availabilityEndDate = equipment.availabilityEndDate;

    let dates = isAfter ? needEndDatesAfter : needStartDatesBefore

    props.getEquipmentById(
      equipment.id,
      equipmentName,
      availabilityStartDate,
      availabilityEndDate,
      notificationStatus,
      dates
    );
  };

  // const handleNeedAfterAction = (event, equipment, needEndDatesAfter) => {
  //   alert("Buyer Needs Equipment before your start date, are you ready to make your asset available now??");
  //   // console.log("equipment", equipment);
  //   let notificationStatus = "After";
  //   let equipmentName = equipment.equipmentName;
  //   let availabilityStartDate = equipment.availabilityStartDate;
  //   let availabilityEndDate = equipment.availabilityEndDate;
  //   props.getEquipmentById(equipment.id, equipmentName, availabilityStartDate, availabilityEndDate, notificationStatus, needEndDatesAfter);
  // }

  // const handleNeedBeforeAction = (event, equipment, needStartDatesBefore) => {
  //   alert("Available before you need");
  //   let notificationStatus = "Before";
  //   // console.log("equipment", equipment);
  //   let equipmentName = equipment.equipmentName;
  //   let availabilityStartDate = equipment.availabilityStartDate;
  //   let availabilityEndDate = equipment.availabilityEndDate;
  //   props.getEquipmentById(equipment.id, equipmentName, availabilityStartDate, availabilityEndDate, notificationStatus, needStartDatesBefore);
  // }

  // console.log("equipmentList", equipmentList);

  let needEquipList = (equipmentList || []).filter((x) => x.needStatus && !x.availabilityStatus)
  let availabilityEquipList = (equipmentList || []).filter((x) => x.availabilityStatus && !x.needStatus)
  let equipAvailableStatus = (availabilityEquipList || []).filter(obj1 => needEquipList.some(obj2 => obj2.make === obj1.make && obj2.model === obj1.model && obj2.createdBy && obj2.modelYear === obj1.modelYear && new Date(obj1.availabilityStartDate) > new Date(obj2.needEndDate) || new Date(obj1.availabilityEndDate) < new Date(obj2.needStartDate)));//make,model,year,dates
  // console.log("equipAvailableStatus", equipAvailableStatus);
  // console.log("needEquipList", needEquipList);
  // console.log("availabilityEquipList", availabilityEquipList);

  const columns = [
    {
      field: "equipmentName",
      headerName: i18n.translate("equipment_name"),
      width: 230,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.equipmentName} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontWeight: "bold",
                  },
                }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                {params.row.equipmentName}
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "companyName",
      headerName: i18n.translate("company"),
      width: 150
    },
    {
      field: "make",
      headerName: i18n.translate("make"),
      width: 100,
    },
    {
      field: "model",
      headerName: i18n.translate("model"),
      width: 120,
    },
    {
      field: "modelYear",
      headerName: i18n.translate("model_year"),
      width: 120
    },
    {
      field: "majorCategory",
      headerName: i18n.translate("major_category"),
      type: "string",
      width: 150,
    },
    // {
    //   field: "fleetType",
    //   headerName: "Fleet Type",
    //   width: 100,
    // },
    {
      field: "mediaLink",
      headerName: i18n.translate("media"),
      width: 100,
      renderCell: (params) => (
        <a
          href={`https://${params.value}`}
          rel="noreferrer"
          target="_blank"
          className={classes.link}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "comparableRetailPrice",
      headerName: i18n.translate("retail_price"),
      type: "string",
      width: 120,
    },
    {
      field: "actions",
      headerName: i18n.translate("actions"),
      sortable: false,
      width: 220,
      renderCell: (params) => {
        const isInEquipAvailableStatusAfter = needEquipList.some(
          (status) =>
            status.make === params.row.make &&
            status.model === params.row.model &&
            status.modelYear === params.row.modelYear &&
            new Date(params.row.availabilityStartDate) > new Date(status.needEndDate)
          // ||
          // new Date(params.row.availabilityEndDate) < new Date(status.needStartDate)
        );

        const needEndDatesAfter = needEquipList
          .filter(
            (status) =>
              status.make === params.row.make &&
              status.model === params.row.model &&
              status.modelYear === params.row.modelYear &&
              new Date(params.row.availabilityStartDate) > new Date(status.needEndDate)
          )
          .map((status) => status.needEndDate);

        const isInEquipAvailableStatusBefore = needEquipList.some(
          (status) =>
            status.make === params.row.make &&
            status.model === params.row.model &&
            status.modelYear === params.row.modelYear &&
            // new Date(params.row.availabilityStartDate) > new Date(status.needEndDate) ||
            new Date(params.row.availabilityEndDate) < new Date(status.needStartDate)
        );

        const needStartDatesBefore = needEquipList
          .filter(
            (status) =>
              status.make === params.row.make &&
              status.model === params.row.model &&
              status.modelYear === params.row.modelYear &&
              new Date(params.row.availabilityEndDate) < new Date(status.needStartDate)
          )
          .map((status) => status.needStartDate);

        // console.log("needStartDatesBefore", needStartDatesBefore);

        return (
          <>
            <Tooltip title={i18n.translate("view_equipment")}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.translate("edit_equipment")}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.translate("delete_equipment")}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.equipmentName +
                    " ?"
                  ) && handleEquipmentDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {(isInEquipAvailableStatusAfter || isInEquipAvailableStatusBefore) && (needEndDatesAfter.length > 0 || needStartDatesBefore.length > 0) &&
              <Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(event) => {
                    handleNeedAction(event, params.row, needEndDatesAfter[0], needStartDatesBefore[0]);
                  }}
                >
                  {"NEED"}
                </Button>
              </Tooltip>
            }
          </>
        );
      },
    },
  ];

  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase()) && companyList) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = async (e) => {
            const payload = GetUploadPayload(reader.result, companyList, userData, userList, loggedInUserData);
            if (payload?.length > 0) {
              const invalidCreatedBy = payload.some(item => !item.createdBy);
              if (invalidCreatedBy) {
                if (loggedInUserData.user.roleSystem === "regular") {
                  alert("Invalid 'createdBy' in the uploaded Excel file.");
                }
                return;

              } else {
                let blobPath = [];
                if (files.length > 0) {
                  blobPath = files.map((file) => file.name);
                  await uploadMultipleFiles("fileupload", "equipment", files);
                  setFiles([]);
                }
                props.submitEquipmentRegisterForm(payload, "upload", loggedInUserData);
              }
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref?.current?.form?.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };


  function DataGridTitle() {
    return (
      <>
        <Accordion expanded={expanded} onChange={handleAccordionToggle}>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            onClick={handleAccordionToggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {i18n.translate("equipment_filtering")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '5px 16px' }}>
            <Grid container sx={{ alignItems: 'center', marginTop: "-10px", marginBottom: "10px" }}>
              <Grid item xs={6} lg={6} md={6} sm={3}>
                <div style={{ width: "fit-content" }}>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<FileUploadIcon />}
                    >
                      Select File
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={ref}
                        className={classes.textInput}
                        onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                        style={{ display: "none" }}
                      />
                    </Button>
                  </div>
                  <div>
                    {file ? file.name : "No file chosen"}
                  </div>
                </div>
                {/* <form>
                  <h3>
                    <div style={{ display: "flex", paddingRight: "5px"}}>
                    {i18n.translate("upload_equipment_excel")}
                    </div>
                    <div>
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={ref}
                        className={classes.textInput}
                        onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                      />
                    </div>
                  </h3>
                </form> */}
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                md={3}
                sm={6}
                sx={{ marginBottom: "25px" }}
              >
                <Grid container>
                  <Grid item xs={6} sm={6} md={4} lg={6}>
                    <Button
                      component="label"
                      variant="contained"
                      // endIcon={<UploadFileOutlinedIcon />}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents accordion closing
                        ImportExport();
                      }}
                      disabled={disabled}
                      size="small"
                    >
                      {/* {i18n.translate("upload_file_button")} */}
                      <FileUploadIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={6}
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xs: 'flex-end',
                      }
                    }}>
                    <a
                      href={Sample_equipment_file}
                      download="Sample_equipment_file"
                      style={{ textDecoration: "none" }}
                      onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                    >
                      <Tooltip title={i18n.translate("sample_excel")}>
                        <Button variant="outlined" size="small" sx={{ color: "#3E5272" }}>
                          <DownloadIcon />
                        </Button>
                      </Tooltip>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={3} sm={3} lg={3}>
                <TextField
                  id="filled-select-currency"
                  select
                  label={i18n.translate("select_company")}
                  variant="outlined"
                  onChange={(e) => {
                    e.stopPropagation(); // Prevents accordion closing
                    handleCompanyChange(e);
                  }}
                  size="small"
                  value={companyValue}
                  sx={{ minWidth: '80%', paddingBottom: "5px" }}
                >
                  {companyList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.id}
                      onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                    >
                      {option.companyName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={3} sm={3} lg={3}>
                <TextField
                  id="filled-select-currency"
                  select
                  label={i18n.translate("select_industry")}
                  variant="outlined"
                  onChange={(e) => {
                    e.stopPropagation(); // Prevents accordion closing
                    handleIndustryChange(e);
                  }}
                  size="small"
                  value={industryValue}
                  sx={{ minWidth: '80%', paddingBottom: "5px" }}
                >
                  {industryList.map((option, index) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                    >
                      {tIndustryList[index]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={3} sm={3} lg={3}>
                <TextField
                  id="filled-select-currency"
                  select
                  label={i18n.translate("select_category")}
                  variant="outlined"
                  onChange={(e) => {
                    e.stopPropagation(); // Prevents accordion closing
                    handleCategoryChange(e);
                  }}
                  size="small"
                  value={categoryValue}
                  sx={{ minWidth: '80%', paddingBottom: "5px" }}
                >
                  {transformedData.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      onClick={(e) => e.stopPropagation()} // Prevents accordion closing
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* <Grid
                item
                xs={12}   // Full width on smaller screens
                md={3}    // 3/12 on medium screens
                sm={3}    // 3/12 on small screens
                lg={3}
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xs: 'flex-start',  // Align right on smaller screens
                    lg: 'flex-end' // Align left on larger screens
                  },
                }}
              >
                <SearchBar
                  searchText={search}
                  filterChange={(e) => {
                    e.stopPropagation(); // Prevents accordion closing
                    onFilterChange(e);
                  }}
                  onClickFunc={(e) => {
                    e.stopPropagation(); // Prevents accordion closing
                    handleClearText();
                  }}
                />
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion >
        <Grid container spacing={2}>
          <Grid item xs={6} lg={9} md={9} sm={9}>
            <Typography variant="h5"
              sx={{
                margin: '6px',
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              {i18n.translate("page_title")}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} md={3} sm={3} sx={{ display: 'flex', justifyContent: "flex-end" }}>
            <Tooltip title={i18n.translate("create_equipment_tooltip")}>
              <Link to="/equipment/create">
                {/* <IconButton sx={{ color: "#3E5272" }}> */}
                  {/* <AddBoxIcon /> */}
                  <Button style={{color: "rgb(255, 255, 255)", backgroundColor: "rgb(62, 82, 114)"}}>{"Create New Equipment"}</Button>
                {/* </IconButton> */}
              </Link>
            </Tooltip>
          </Grid>
        </Grid >
      </>
    );
  }

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setCompanyValue(selectedCompany);

    // Filter equipment based on selected company and industry and category
    filterEquipment(selectedCompany, industryValue, categoryValue);
  };

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setIndustryValue(selectedIndustry);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, selectedIndustry, categoryValue);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategoryValue(selectedCategory);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, industryValue, selectedCategory);
  };

  const filterEquipment = (selectedCompany, selectedIndustry, selectedCategory) => {
    let filteredList = equipmentList;

    if (selectedCompany) {
      filteredList = filteredList.filter((item) => item.companyId === selectedCompany);
    }

    if (selectedIndustry) {
      filteredList = filteredList.filter((item) => item.industry === selectedIndustry);
    }

    if (selectedCategory) {
      filteredList = filteredList.filter((item) => item.majorCategory === selectedCategory);
    }

    setFilteredEquipmentList(filteredList);

    // setPageState((old) => ({ ...old, total: filteredList.length }));
  };

  const transformedData = industyCategories.reduce((acc, category) => {
    const categoryOptions = category.majorCategory.map((subcategory) => ({
      value: subcategory,
      label: subcategory,
    }));

    return [...acc, ...categoryOptions];
  }, []);


  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Card takes 100% height of its parent
        minHeight: 0,   // Ensures proper handling of flexbox in some situations
        padding: '15px'
      }}
    >
      <SearchBar
        searchText={search}
        filterChange={onFilterChange}
        onClickFunc={handleClearText}
      />
      <DataGridTitle />
      <ThemeProvider theme={theme}>
        <DataGridPro
          rows={filteredEquipmentList || []}
          columns={columns}
          rowCount={rowCountState}
          paginationMode="server"
          rowsPerPageOptions={[100]}
          pagination
          onPaginationModelChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage.page + 1 }));
          }}
          // checkboxSelection
          disableSelectionOnClick
          sortingMode="server"
          onSortModelChange={(col) => {
            if (col.length > 0) {
              if (col[0].sort === "asc") {
                setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
              } else {
                setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
              }
            } else {
              setPageState((old) => ({ ...old, sort: null }));
            }
          }}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              color: "#ee9907",
            },
            // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            //   display: 'none'
            // }
          }}
          // density='standard'
          // components={{ Toolbar: DataGridTitle }}
          components={{
            Toolbar: DataGridTitle,
            Pagination: GridPagination,
          }}
          localeText={i18n.dataGridLocaleProp}
        />
      </ThemeProvider>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company && state?.company?.companyList?.companyList,
  equipmentList: state.equipment && state?.equipment?.equipmentList?.equipmentList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  totalCount: state.equipment && state?.equipment?.equipmentList?.total,
  userData: state.user.userData,
  userList: state.user && state.user.userList.userList,
  locale: state.locale
});
export default connect(mapStateToProps, {
  submitEquipmentRegisterForm,
  getEquipmentList,
  getEquipmentById,
  deleteEquipment,
  getCompanyList,
  setLoggedInUserData,
  getUserList
})(EquipmentList);
