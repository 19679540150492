import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderText } from '../../styles/appStyles';
import { schema, initialFormValues, getPayload, userSelctableRoles } from './utils';
import { submitRegisterForm } from '../../reducers/UserReducer';
import validate from 'validate.js';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../styles/formStyles';

import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import close icon
import IconButton from '@mui/material/IconButton';

import getTranslationObject from '../../utils/getTranslations';

// const useStyles = makeStyles(theme => ({
//     root: {
//         '& .MuiButtonBase-root': {
//             margin: theme.spacing(2),
//         },
//         '& .MuiContainer-root': {
//             //marginTop:""
//         },
//     },
// }));

const RegisterNew = props => {
    // const { restClient: { rest } } = props;
    const { locale } = props;
    const i18n = getTranslationObject(locale, "registration_page");

    const [open, setOpen] = React.useState(false);

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState('');

    const handleCheckboxChange = () => {
        setError('');
        setIsChecked(!isChecked);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAccept = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });


    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleStateChange = (field, value) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [field]: value
            },
            touched: {
                ...prevFormState.touched,
                [field]: true
            }
        }));
    };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);

    const handleStartCaseChange = (event) => {
        const { name, value } = event.target;
        handleStateChange(name, StartCase(value));
    }

    const StartCase = (text) => {
        return text
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    };

    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }

        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));

        return isErrorExist;
    }

    const submitForm = event => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            const payload = getPayload(values);
            if (!isChecked) {
                setError('Please Accept the terms and conditions.');
                return;
            }
            props.submitRegisterForm(payload);
        }
    }

    return (
        <Card sx={{ maxWidth: 600, height: '100%', overflowY: 'scroll', mx: 'auto', padding: '15px' }}>
            <HeaderText style={{ marginTop: "10px", fontSize: "x-large", marginBottom: "-10px" }}>
                {i18n.translate("register_title")}
            </HeaderText>
            <CardContent >
                <form onSubmit={submitForm}>
                    <FormControlWrapper>
                        <FormControl>
                            <TextField
                                error={hasError('firstName')}
                                helperText={
                                    hasError('firstName') && i18n.translate("error_first_name")
                                }
                                variant="outlined"
                                data-test-id="Register-User-FirstName"
                                value={formState.values.firstName}
                                label={i18n.translate("first_name")}
                                fullWidth
                                // size='small'
                                placeholder={i18n.translate("first_name")}
                                type="text"
                                name="firstName"
                                onChange={handleStartCaseChange}
                            />
                        </FormControl>
                    </FormControlWrapper>
                    <FormControlWrapper>
                        <FormControl>
                            <TextField
                                error={hasError('lastName')}
                                helperText={
                                    hasError('lastName') && i18n.translate("error_last_name")
                                }
                                variant="outlined"
                                data-test-id="Register-User-LastName"
                                value={formState.values.lastName}
                                label={i18n.translate("last_name")}
                                fullWidth
                                // size='small'
                                placeholder={i18n.translate("last_name")}
                                type="text"
                                name="lastName"
                                onChange={handleStartCaseChange}
                            />
                        </FormControl>
                    </FormControlWrapper>
                    {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('emailAddress')}
                                            helperText={
                                                hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Email"
                                            value={formState.values.emailAddress}
                                            label="Email Address"
                                            fullWidth
                                            placeholder="Email Address"
                                            type="email"
                                            name="emailAddress"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                    <FormControlWrapper>
                        <FormControl>
                            <TextField
                                error={hasError('email')}
                                helperText={
                                    hasError('email') && i18n.translate("error_email")
                                }
                                variant="outlined"
                                data-test-id="Register-User-Email"
                                value={formState.values.email}
                                label={i18n.translate("email")}
                                fullWidth
                                // size='small'
                                placeholder={i18n.translate("email")}
                                type="email"
                                name="email"
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormControlWrapper>
                    {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('password')}
                                            helperText={
                                                hasError('password') ? formState.errors.password[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Password"
                                            value={formState.values.password || ''}
                                            label="Password"
                                            fullWidth
                                            placeholder="Password"
                                            type="text"
                                            name="password"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                    {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('confirmPassword')}
                                            helperText={
                                                hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
                                            }
                                            value={formState.values.confirmPassword || ''}
                                            variant="outlined"
                                            label="Confirm Password"
                                            fullWidth
                                            onChange={handleChange}
                                            data-test-id="Register-User-confirmPassword"
                                            placeholder="Confirm Password"
                                            type="password"
                                            name="confirmPassword"
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                    <FormControlWrapper>
                        <FormControl variant="outlined" className='w-100'
                        >
                            <InputLabel id="roleType-select-outlined-label">{i18n.translate("role_placeholder")}</InputLabel>
                            <Select
                                error={hasError('roleType')}
                                labelId="roleType-select-outlined-label"
                                id="roleType-select-outlined"
                                label={i18n.translate("role_placeholder")}
                                // size='small'
                                data-test-id="Register-User-RoleType"
                                placeholder={i18n.translate("role_placeholder")}
                                value={formState.values.roleType}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'roleType',
                                    id: 'roleType',
                                }}
                            >
                                <MenuItem value="0">{i18n.translate("select_role")}</MenuItem>
                                {userSelctableRoles.map((roleType) => <MenuItem value={roleType.name}>
                                    {i18n.translate("user_selectable_roles")[roleType.name]}
                                </MenuItem>)}

                            </Select>
                            {hasError('roleType') && (
                                <FormHelperText htmlFor="form-selector" error={hasError('roleType')}>
                                    {formState.errors.roleType[0]}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </FormControlWrapper>
                    <FormControlWrapper>
                        <FormControl>
                            <TextField
                                error={hasError('mobileNumber')}
                                helperText={
                                    hasError('mobileNumber') && i18n.translate("error_mobile_number")
                                }
                                variant="outlined"
                                data-test-id="Register-User-Phone"
                                value={formState.values.mobileNumber}
                                label={i18n.translate("phone")}
                                fullWidth
                                // size='small'
                                placeholder={i18n.translate("phone")}
                                type="numaric"
                                name="mobileNumber"
                                onChange={handleStartCaseChange}
                            />
                        </FormControl>
                    </FormControlWrapper>
                    <FormButtonWrapper className="align-box-row justify-box-row">
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            className="my-2"
                        // disabled={!isChecked}
                        >
                            {i18n.translate("submit")}
                        </Button>
                        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                    </FormButtonWrapper>
                    <div style={{ marginTop: '1rem', marginBottom: '1rem', cursor: 'pointer', color: "#FFBF00" }} >
                        <React.Fragment>
                            <div variant="outlined" onClick={handleClickOpen}>
                                <Typography align='center'>
                                    {i18n.translate("accept_terms")}
                                </Typography>
                            </div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle>
                                    Terms & Conditions
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        style={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: 'gray',
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <p>Welcome to  <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile.</a></p>
                                        <p>These terms and conditions outline the rules and regulations for the use of <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile ’s</a> Website.</p>
                                        <p>By accessing this website we assume you accept these terms and conditions in full.</p>
                                        <p>Do not continue to use <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile’s</a> website if you do not accept all of the terms and conditions stated on this page.</p>
                                        <p>Please read and accept the terms:</p>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    color="primary"
                                                />
                                            }
                                            label="I accept the terms and conditions"
                                        />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Close</Button>
                                    <Button
                                        onClick={handleAccept}
                                        color="primary"
                                        variant="contained"
                                        disabled={!isChecked}
                                    >
                                        Accept
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    </div>
                    {/* <FormButtonWrapper className="align-box-row justify-box-row">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className="my-2"
                                        // disabled={!isChecked}
                                        >
                                        Submit
                                    </Button>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </FormButtonWrapper> */}
                    <div style={{ marginTop: '1rem' }}>
                        <Typography align='center'>  <Link to="/login" style={{ color: '#FFBF00', textDecoration: 'none' }}>{i18n.translate("login_link")}</Link>  </Typography>
                    </div>
                </form >
            </CardContent >
        </Card >
    );
}

const mapStateToProps = state => ({
    locale: state.locale,
    restClient: state.restClient,
});

export default connect(mapStateToProps, { submitRegisterForm })(RegisterNew);

