import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  IconButton,
  Grid,
  Button,
  FormControl,
  TextField,
  Card,
  Typography,
  useTheme,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  FormLabel,
  FormGroup,
  FormControlLabel,
  ListItemAvatar,
  Tooltip,
  Box,
  Tab,
  Tabs,
  InputAdornment,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  AppBar,
  Toolbar,
  useMediaQuery,
  Paper,
  ListItemButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import useQuery from '../../utils/useQuery';
import TabsContainer from "./Card";
import { maxHeight, minHeight, Stack, styled } from '@mui/system';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from 'react-slick';  // For horizontal sliding, install react-slick and slick-carousel
import { FileCard } from "@files-ui/react";
import { editDeal } from "../../reducers/DealReducer";
import { getDealMessageList, getDealMessageListForPlan, submitDealMessage, submitAcceptMessage, submitRejectMessage, submitDealMessageWithoutTransactionId, editTransactionBlobs, uniqueFromIds } from '../../reducers/DealCommunicationReducer';
import { getTransactionList, GenerateTransactionRegisterForm, editTransaction } from "../../reducers/TransactionReducer";
import { uploadImage, deleteImage } from "./blobStorage";
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format, isToday, isYesterday, startOfWeek, isSameWeek, differenceInMinutes } from 'date-fns';
import { getCompanyById } from "../../reducers/CompanyReducer";
import Eagle from "./eagle.jpeg";
import CloseIcon from '@mui/icons-material/Close';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getTranslationObject from "../../utils/getTranslations";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  messageRow: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    marginRight: "30%",
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20
        }
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20
        }
      }
    },
    "&.me": {
      paddingLeft: 40,

      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px"
      },
      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20
        }
      },

      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20
        }
      }
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13
      }
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex"
        }
      }
    }
  },
  messageRowRight: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12,
        },
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20,
        },
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20,
        },
      },
    },
    "&.me": {
      paddingLeft: 40,
      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px",
      },
      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12,
        },
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20,
        },
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20,
        },
      },
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20,
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13,
      },
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex",
        },
      },
    },
  },
  avatar: {
    position: "absolute",
    left: 0,
    margin: 0
  },
  bubble: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    maxWidth: "100%",
    wordWrap: "break-word",
    boxShadow: theme.shadows[1]
  },
  message: {
    whiteSpace: "pre-wrap",
    lineHeight: 1.2
  },
  time: {
    position: "absolute",
    display: "none",
    width: "100%",
    fontSize: 11,
    marginTop: 8,
    top: "100%",
    left: 0,
    whiteSpace: "nowrap"
  },
  inputWrapper: {
    borderRadius: 24
  },
  rightMessage: {
    width: "fit-content",
    alignSelf: "end"
  },
  ButtonGroup: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  AcceptButton: {
    borderRadius: "30px",
    color: "white",
    backgroundColor: "rgb(62, 82, 114)",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  RejectButton: {
    borderRadius: "30px",
    color: "#fff",
    backgroundColor: "#9c27b0",
    '&:hover': {
      backgroundColor: "#6C3483"
    }
  },
  accept: {
    textAlign: "start",
  },
  reject: {
    textAlign: "end",
  },
  dialogButtons: {
    display: "flex"
  },
  achPayment: {
    paddingRight: "30px",
  },
  achlink: {
    textDecoration: "none",
  },
  cardlink: {
    textDecoration: "none"
  },
  achButton: {
    backgroundColor: "rgb(62, 82, 114)",
    color: "white",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  cardButton: {
    backgroundColor: "#A569BD",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#884EA0"
    }
  },
  cancelButton: {
    backgroundColor: "#f50057",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#f50057",
      color: "#fff"
    }
  },
  makePayment: {
    textAlign: "center",
  },
}));

const FileCardsDisplay = ({ files, dealId, editTransactionBlobs, allFiles, transactionId }) => {
  const fileName = files?.blobPath; //array of strings
  const fileGroups = files?.blobPath?.length > 0 && fileName.reduce((acc, file) => {
    const extension = file.split('.').pop();
    if (!acc[extension]) {
      acc[extension] = [];
    }
    acc[extension].push(file);
    return acc;
  }, {});
  const baseUrl = process.env.REACT_APP_BLOB_URL;

  const parsedFiles = Object.keys(fileGroups).reduce((acc, key) => {
    acc[key] = fileGroups[key].map(url => `${baseUrl}deal/${dealId}/` + url);
    return acc;
  }, {});
  const [imgSrc, setImgSrc] = React.useState(undefined);
  const [videoSrc, setVideoSrc] = React.useState(undefined);

  const handleDelete = (fileType, fileName) => {
    console.log(`Delete ${fileName} from ${fileType}`);
    // Implement the delete functionality here
    const decision = window.confirm("Are u sure you need to delete the file permanently?");
    const res = decision && deleteImage('deal', `${dealId}/${fileName}`);
    let transactioncommunication = {};
    transactioncommunication.blobPath = allFiles.filter((file) => file === fileName);
    // editTransactionBlobs(transaction?.id, transactioncommunication);
  };

  const handleSee = (imageSource) => {
    setImgSrc(imageSource);
  };

  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };

  const handleDownload = (fileId, downloadUrl) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileId; // or any other filename you wish to use
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  function getFileExtension(filename) {
    const match = filename.match(/\.([^\.]+)$/);
    const fileType = match ? match[1] : '';
    let finalFileType;
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'].includes(fileType)) {
      finalFileType = `image/${fileType}`;
    } else if (fileType === 'pdf') {
      finalFileType = `application/pdf`;
    } else if (['xls', 'xlsx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
    } else if (fileType === 'csv') {
      finalFileType = `text/${fileType}`;
    } else if (['doc', 'docx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    } else if (['ppt', 'pptx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.presentationml.presentation`;
    } else if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'].includes(fileType)) {
      finalFileType = `video/${fileType}`;
    } else if (['mp3', 'wav', 'aac', 'flac', 'ogg'].includes(fileType)) {
      finalFileType = `audio/${fileType}`;
    } else if (['txt', 'md', 'rtf'].includes(fileType)) {
      finalFileType = `text/${fileType}`;
    } else {
      finalFileType = 'application/octet-stream';
    }
    return finalFileType
  }
  return (
    <>
      {Object.entries(parsedFiles).map(([fileType, fileArray]) =>
        fileArray.map((fileUrl) => {
          const fileName = fileUrl.split('/').pop();
          return (
            <FileCard
              key={fileName}
              id={fileName}
              name={fileName}
              type={getFileExtension(fileName)}
              onSee={() => handleSee(fileUrl)}
              onWatch={() => handleWatch(fileUrl)}
              onDownload={() => handleDownload(fileName, fileUrl)}
              preview
            // Add other props as needed
            />
          );
        })
      )}
    </>
  );
};

const BorderRight500 = styled('div')(({ theme }) => ({
  borderRight: '1px solid #e0e0e0',
}));

const UserList = ({ loggedInUserData, sortedUserList,
  setSelectedUsers, setTransaction,
  dealID, equipment,
  GenerateTransactionRegisterForm, dealTransactionList,
  setToGroupMembers, submitDealMessageWithoutTransactionId,
  editTransaction, setSelectedTabId, selectedTabId,
  group, setGroup, setNameOfSelectedContact,
  isSmallScreen, value, setValue,
  dealMessageList, locale }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [editGroupName, setEditGroupName] = useState('');
  const [groupMembers, setGroupMembers] = useState([]);
  const [dealGroups, setDealGroups] = useState([]);
  const [editGroupMembers, setEditGroupMembers] = useState([]);
  const [dealMessages, setDealMessages] = useState([]);
  const i18n = getTranslationObject(locale, "deal_chat_page")

  useEffect(() => {
    if (dealMessageList.length) {
      setDealMessages(dealMessageList);
    }
  }, [dealMessageList]);

  const handleEditOpenDialog = (event, transaction) => {
    event.stopPropagation();
    setTransaction(transaction);
    setOpenEditDialog(true);
    setEditGroupMembers(transaction?.userGroup);
    setEditGroupName(transaction?.selectionName);
    setNameOfSelectedContact(transaction?.selectionName);
  };

  const handleEditCloseDialog = () => {
    setOpenEditDialog(false);
  };

  // for opening the group creation dialogue panel
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  // for closing the group creation dialogue panel
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGroupName("");
    setGroupMembers([]);
  };

  // For changing the tabs user-wise
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setGroup([]);
  };

  // For changing the tabs group-wise
  const handleGroupChange = (event, newGroup) => {
    setGroup(newGroup);//0
    setValue([]);
  };

  // For selecting the members for group creation
  const handleCheckboxForGroupCreationChange = (event, user) => {
    if (event.target.checked) {
      setGroupMembers((prevSelectedUsers) => [...prevSelectedUsers, user]);
    } else {
      setGroupMembers((prevSelectedUsers) =>
        prevSelectedUsers.filter((u) => u.participant._id !== user.participant._id)
      );
    }
  };

  // for group creation
  const transactionCommunication = {
    dealId: dealID,
    message: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} created this group chat`,
    transactionEquipment: equipment,
    from: `${loggedInUserData.user._id}`,
    to: groupMembers.map((user) => user.participant._id),
    notificationType: "General Message",
    addedBy: `${loggedInUserData.user._id}`,
  };

  // For creating the group
  const handleCreateGroupChat = async () => {
    const otherGroupMembers = groupMembers.map(members => members.participant._id);
    otherGroupMembers.push(loggedInUserData.user._id);
    const transaction = {
      transaction: {
        dealId: dealID,
        equipment: equipment,
        selectionName: groupName,
        price: "$345",
        addedBy: `${loggedInUserData.user._id}`,
        userGroup: otherGroupMembers, // need to add all members
        transactionType: "Group"
      }
    };
    await GenerateTransactionRegisterForm(dealID, transaction, { ...transactionCommunication, selectionName: groupName });
    setOpenDialog(false);
  };

  const isUserInGroup = (transaction, user, userId) => {
    return transaction?.userGroup?.includes(user?.participant?._id) && transaction?.userGroup?.includes(userId) && transaction?.userGroup?.length == 2;
  };

  // for individual chatting
  const handleCheckboxChange = (event, transaction, user) => {
    const isChecked = event.target;
    if (isChecked && transaction) {
      setSelectedUsers([user]);
      setNameOfSelectedContact(`${user?.participant?.contact?.firstName[0]} ${user?.participant?.contact?.lastName[0]}`);
      setTransaction(transaction);
      setToGroupMembers([]);
    }
  };

  // for group chatting
  const handleCheckboxForGroupChange = (user) => {
    setToGroupMembers(user.userGroup.filter((ub) => ub !== loggedInUserData?.user?._id));
    setTransaction(user);//
    setNameOfSelectedContact(user?.selectionName);
    setSelectedUsers([]);
    setSelectedTabId(user?.id);
  };

  const handleTabClick = (event, transaction) => {
    setSelectedTabId(transaction?.id);
    handleCheckboxForGroupChange(transaction);
  };

  const filterCondition = useCallback(
    (transaction) =>
      transaction.transactionType === 'Group' &&
      transaction.userGroup.length > 2 &&
      transaction.userGroup.includes(loggedInUserData.user._id),
    [loggedInUserData.user._id]
  );

  useEffect(() => {
    // Apply the filter condition when dealTransactionList or loggedInUserData.user._id changes
    const filteredGroups = (dealTransactionList || []).filter(filterCondition);
    setDealGroups(filteredGroups);
  }, [dealTransactionList, filterCondition]);
  // To exit from the group
  const handleExitFromGroup = (event, transaction) => {
    event.stopPropagation();
    const decision = window.confirm(`Are you sure you want to exit from group ${transaction?.selectionName} `);
    const payload = {
      userGroup: transaction.userGroup.filter((users) => users !== loggedInUserData.user._id),
    };
    if (transaction.id === selectedTabId) {
      setTransaction({});
      setGroup(null);
      setSelectedTabId(null); // Reset selectedTabId when you exit from the group
    }
    if (decision) {
      editTransaction(transaction?.dealId, transaction?.id, payload, loggedInUserData);
    }
  };

  // Edit Group MEMBERS
  const handleCheckboxForGroupEditChange = (event, user) => {
    event.stopPropagation();
    const userId = user.participant._id;
    setEditGroupMembers(prevGroupMembers => {
      if (event.target.checked) {
        return [...prevGroupMembers, userId];
      } else {
        return prevGroupMembers.filter(id => id !== userId);
      }
    });
  };
  // Edit group
  const handleEditGroup = (event) => {
    const payload = {
      selectionName: editGroupName,
      userGroup: editGroupMembers,
    };
    setToGroupMembers(editGroupMembers.filter((user) => user !== loggedInUserData.user._id));
    editTransaction(dealID, selectedTabId, payload);
    setOpenEditDialog(false);
    setNameOfSelectedContact(editGroupName);
  };
  const TabLevelComponent = ({ user, dealMessages, dealTransactionList }) => {
    const reqTransactionId = (dealTransactionList || []).find((transaction) =>
      transaction.transactionType === "Individual" &&
      transaction.userGroup.includes(user?.participant?._id) &&
      transaction.userGroup.includes(loggedInUserData?.user?._id)
    )?.id;
    const messageList = dealMessages?.filter((message) => message?.transactionId?._id === reqTransactionId);
    function formatWhatsAppDate(dateString) {
      // Check if dateString is valid
      if (!dateString) {
        return ''; // Handle invalid dates
      }

      const date = new Date(dateString);

      // If date is invalid, return a default value
      if (isNaN(date.getTime())) {
        return ''; // Handle invalid dates
      }
      const now = new Date();

      // Check if the message is less than an hour old
      const minutesDifference = differenceInMinutes(now, date);

      if (minutesDifference < 60) {
        // If less than an hour, return time in minutes
        return `${minutesDifference} min ago`;
      } else if (isToday(date)) {
        // Return the time if the message was sent today
        return format(date, 'p'); // Example: '2:35 PM'
      } else if (isYesterday(date)) {
        // Return 'Yesterday' if the message was sent yesterday
        return 'Yesterday';
      } else if (isSameWeek(date, now, { weekStartsOn: 1 })) {
        // Return the day of the week for messages in the same week
        return format(date, 'EEEE'); // Example: 'Monday'
      } else {
        // Return the full date for older messages
        return format(date, 'MMMMMMMM d yyyy'); // Example: 'Sep 15 2024'
      }
    }
    return (
      <>
        <Box>
          <Typography variant="body1">
            {`${user.participant.contact.firstName[0]} ${user.participant.contact.lastName[0]}`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
              {messageList[messageList.length - 1]?.message.length > 20 ? `${messageList[messageList.length - 1]?.message.substring(0, 20)}...` : messageList[messageList.length - 1]?.message || 'image'}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
              {formatWhatsAppDate(messageList[messageList.length - 1]?.createdAt)} {/* Replace with actual subheading */}
            </Typography>
          </Box>

        </Box>
      </>
    )
  }

  const extractSenderInitialsAndMessage = (dataArray) => {
    // Get the last object in the array
    const lastObject = dataArray[dataArray.length - 1];

    // Extract the first and last name initials
    const firstNameInitial = lastObject?.from?.contact?.firstName[0] || "";
    const lastNameInitial = lastObject?.from?.contact?.lastName[0] || "";

    // Extract the message
    const message = lastObject?.message || "Image";

    // Return the initials and message as an object
    return `${firstNameInitial} ${lastNameInitial}:${message}`;
  };

  const extractSenderInitialsAndMessageTime = (dataArray) => {
    // Get the last object in the array
    const lastObject = dataArray[dataArray.length - 1];
    function formatWhatsAppDate(dateString) {
      // Check if dateString is valid
      if (!dateString) {
        return ''; // Handle invalid dates
      }

      const date = new Date(dateString);

      // If date is invalid, return a default value
      if (isNaN(date.getTime())) {
        return ''; // Handle invalid dates
      }
      const now = new Date();

      // Check if the message is less than an hour old
      const minutesDifference = differenceInMinutes(now, date);

      if (minutesDifference < 60) {
        // If less than an hour, return time in minutes
        return `${minutesDifference} min ago`;
      } else if (isToday(date)) {
        // Return the time if the message was sent today
        return format(date, 'p'); // Example: '2:35 PM'
      } else if (isYesterday(date)) {
        // Return 'Yesterday' if the message was sent yesterday
        return 'Yesterday';
      } else if (isSameWeek(date, now, { weekStartsOn: 1 })) {
        // Return the day of the week for messages in the same week
        return format(date, 'EEEE'); // Example: 'Monday'
      } else {
        // Return the full date for older messages
        return format(date, 'MMM d yyyy'); // Example: 'Sep 15 2024'
      }
    }
    // Return the initials and message as an object
    return `${formatWhatsAppDate(lastObject?.createdAt)}`;
  };

  const getLatestMessage = (transaction, dealMessages) => {
    const recentMessages = dealMessages.filter((message) =>
      message?.transactionId?._id === transaction?.id
    );
    // Return the formatted message with the sender's name
    return extractSenderInitialsAndMessage(recentMessages);
  };

  return (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar alt={`${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} `} src={Eagle} />
          </ListItemIcon>
          <ListItemText primary={`${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} `} />{/*For displaying loggedIn userName*/}
          <ListItemAvatar>
            <Tooltip title={i18n.translate("add_group_chat_tooltip")}>
              <IconButton onClick={handleOpenDialog}>{/*For displaying the Group Creation Icon */}
                <GroupAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </ListItemAvatar>
        </ListItem>
      </List>
      <Divider />
      {sortedUserList.length > 0 ?
        <List sx={{ borderRight: 1, borderColor: 'divider' }}>
          {(sortedUserList || []).map((user, index) => (
            <ListItem
              key={user.participant._id}
              button
              selected={value === index}
              onClick={(event) => {
                handleChange(event, index); // Call handleChange with the index of the selected item

                const ifTransactionIdExists = (dealTransactionList || []).filter(transaction =>
                  isUserInGroup(transaction, user, loggedInUserData?.user?._id)
                );
                if (ifTransactionIdExists.length) {
                  handleCheckboxChange(event, ifTransactionIdExists[0], user);
                } else {
                  const transaction = {
                    dealId: dealID,
                    equipment: equipment.map((un) => un._id),
                    addedBy: loggedInUserData.user._id,
                    transactionType: "Individual",
                    selectionName: "selectionName",
                    price: "$345",
                    userGroup: [user?.participant?._id, loggedInUserData.user._id],
                  };
                  submitDealMessageWithoutTransactionId(dealID, transaction)
                    .then((response) => {
                      const transaction = {
                        id: response.transaction._id,
                        equipment: response.transaction.equipment,
                        dealId: response.transaction.dealId,
                        selectionName: response.transaction.selectionName,
                        price: response.transaction.price,
                        addedBy: response.transaction.addedBy,
                        userGroup: response.transaction.userGroup,
                        transactionType: response.transaction.transactionType,
                      };
                      handleCheckboxChange(event, transaction, user);
                    })
                    .catch((error) => {
                      console.error('Error:', error);
                    });
                }
              }}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                marginTop: 0.2,
                maxWidth: '100%',
              }}
            >
              {/* Custom ListItem content */}
              <ListItemText
                primary={<TabLevelComponent user={user} dealMessages={dealMessages} dealTransactionList={dealTransactionList} />} // Use your custom component here
              />
            </ListItem>
          ))}
        </List>
        : null}
      <Divider />
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>{/* Group Creation Dialog box*/}
        <TextField
          size="small"
          fullWidth
          placeholder={i18n.translate("group_name_placeholder")}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          required
          sx={{ marginTop: "10px" }}
        />
        <DialogContent>{/*For displaying the dealParticipants in groupCreation*/}
          {(sortedUserList || []).map((user) => (
            <ListItem key={user.participant._id}>
              <ListItemIcon>
                <Checkbox
                  checked={groupMembers.some((u) => u.participant._id === user.participant._id)}
                  onChange={(event) => handleCheckboxForGroupCreationChange(event, user)}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${user.participant.contact.firstName} ${user.participant.contact.lastName} `}
              />
            </ListItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{/*Warning for selection of users*/}
              {groupMembers.length < 2 &&
                <Typography variant="caption" sx={{ color: "red" }}>
                  {i18n.translate("select_2_users")}
                </Typography>}
            </Grid>
            <Grid item>
              <Button onClick={handleCloseDialog} color="primary">
                {i18n.translate("cancel")}
              </Button>
              <Button
                onClick={handleCreateGroupChat}
                color="primary"
                disabled={groupMembers.length < 2 || groupName.trim() === ""}
              >
                {i18n.translate("create_group_chat")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {dealGroups.length ? (
        <>
          <ListItem sx={{ backgroundColor: '#fbbc05' }}>
            <ListItemText id="switch-list-label-bluetooth" primary="Group Chats" />
            <ListItemIcon>
              <Badge badgeContent={dealGroups.length} color="primary" />
            </ListItemIcon>
          </ListItem>
          <Divider />
          <List sx={{ height: '100%', overflowY: 'auto', borderRight: 1, borderColor: 'divider' }}>
            {(dealGroups || []).map((transaction, index) => (
              <ListItemButton
                key={transaction.id}
                selected={group === index} // Highlight selected group
                onClick={(event) => {
                  handleGroupChange(event, index); // Change group on click
                  handleTabClick(event, transaction); // Handle the tab click functionality
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: '100%',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  marginTop: 0.2,
                  maxWidth: '100%',
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{transaction.selectionName}</span>
                        {transaction.addedBy !== loggedInUserData.user._id ? (
                          <IconButton style={{ marginLeft: 48 }} onClick={(event) => handleExitFromGroup(event, transaction)}>
                            <PersonRemoveAlt1RoundedIcon />
                          </IconButton>
                        ) :
                          (<IconButton onClick={(event) => handleEditOpenDialog(event, transaction)}>
                            <EditNoteIcon />
                          </IconButton>)}
                      </div>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '100%' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '12px',
                            color: 'gray',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'  // Set max width for ellipsis to apply
                          }}
                        >
                          {getLatestMessage(transaction, dealMessages)?.length > 20
                            ? `${getLatestMessage(transaction, dealMessages).slice(0, 20)}...`
                            : getLatestMessage(transaction, dealMessages)} {/* Replace with actual subheading */}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                          {extractSenderInitialsAndMessageTime(dealMessages)} {/* Replace with actual subheading */}
                        </Typography>
                      </Box>
                    </>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </>
      ) : null}
      <Dialog open={openEditDialog} onClose={handleEditCloseDialog} fullWidth>{/*Accordion for editing the group*/}
        <TextField
          size="small"
          fullWidth
          placeholder="Edit your Group Name"
          value={editGroupName}
          onChange={(e) => setEditGroupName(e.target.value)}
          required
          sx={{ marginTop: "10px" }}
        />
        <DialogContent>
          {(sortedUserList || []).map((user) => (
            <ListItem key={user.participant._id}>
              <ListItemIcon>
                <Checkbox
                  checked={(editGroupMembers || []).includes(user.participant._id)}
                  onChange={(event) => handleCheckboxForGroupEditChange(event, user)}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${user.participant.contact.firstName} ${user.participant.contact.lastName} `}
              />
            </ListItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {editGroupMembers.length < 3 &&
                <Typography variant="caption" sx={{ color: "red" }}>
                  {i18n.translate("select_2_users")}
                </Typography>}
            </Grid>
            <Grid item>
              <Button onClick={handleEditCloseDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={(event) => handleEditGroup(event)}
                color="primary"
                disabled={editGroupMembers?.length < 3 || editGroupName?.trim() === ""}
              >
                Edit Group Chat
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function getBlobUrls(uploadResults) {//For mapping the multiple files and the urls
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) { //For uploading the multiple files at a tiime.
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}
const DealMessageChat = (props) => {
  const { dealCommunicationList = [], restClient: { rest }, getDealMessageList, getDealMessageListForPlan, getCompanyById, loggedInUserData, dealData, editTransactionBlobs, getTransactionList, IndividualMessageList = [], GroupMessageList = [], transactionList = [], GenerateTransactionRegisterForm, submitDealMessageWithoutTransactionId, editTransaction, isBuyer, isSeller, usertype, locale, companyData = {} } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [dealMessageList, setDealMessageList] = useState([]);
  const [unreadMessageList, setUnreadMessageList] = useState([]);
  const [filteredUserList, setFilterUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dealTransactionList, setDealTransactionList] = useState([]);
  const query = useQuery();
  const dealID = query.get('dealId');
  const [open, setOpen] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [toGroupMembers, setToGroupMembers] = useState([]);
  const [makePaymentButtonVisble, setMakePaymentButtonVisble] = useState(true);
  const [makeAcceptButtonVisble, setMakeAcceptButtonVisble] = useState(true);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [nameOfSelectedContact, setNameOfSelectedContact] = useState("");
  const [value, setValue] = React.useState();
  const [group, setGroup] = useState(null);
  const [parentCompany, setParentCompany] = useState({});
  const [exceedingDeals, setExceedingDeals] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const data = useMemo(() => location?.state?.state, [location?.state?.state]);
  const i18n = getTranslationObject(locale, "deal_chat_page");

  const handleClickOpen = (e) => {
    e.stopPropagation(); // Prevents event from bubbling up
    if (!open) {         // Ensure it only triggers if dialog isn't already open
      setOpen(true);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const userId = props.user && props.user.user && props.user.user._id;

  // const DealMakeOffer = dealCommunicationList && dealCommunicationList.find((item) => item.notificationType === "Make Offer");

  const DealAcceptOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Accept Offer");

  const DealRejectOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Reject Offer");

  // const DealPayment = dealCommunicationList?.find && dealCommunicationList.find((item) => item.notificationType === "Payment");

  const ENTER_KEY_CODE = 13;

  const [msg, setMsg] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [intialized, setIntialized] = useState(true);
  // const [selectedGroupId, setSelectedGroupId] = useState("");

  const handleMessageChange = (event) => {
    if ((selectedUsers?.length === 0 && toGroupMembers.length === 0) && msg.trim() == "") {
      alert("You must select atleast one user to send message.");
    } else {
      setMsg(event.target.value);
      setBtnDisabled(!event.target.value);;
    }
  }

  const listmsg = filteredUserList.find(item => item.message);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (filteredUserList.length > 0) {
      scrollToBottom();
    }
    setTimeout(() => {
      if (filteredUserList.length > 0) {
        setIntialized(false);
      }
    }, 2000);
  }, [filteredUserList, scrollToBottom]);

  //for making the unreadmessageList zero
  const handleFocusChange = () => {
    console.log("Focus executed")
    const loggedInUserId = loggedInUserData.user._id;
    const messagesCountOfUser = unreadMessageList?.length > 0 ? unreadMessageList?.filter((user) => user.userId === loggedInUserId)[0]?.totalUnReadMessages : props?.dealData?.UnreadMessages.filter((user) => user.userId === loggedInUserId)[0].totalUnReadMessages;
    if (unreadMessageList?.length > 0 && messagesCountOfUser) {
      unreadMessageList.forEach(message => {
        // Check if the userId matches the loggedInUserId
        if (message.userId === loggedInUserId) {
          // Update totalUnReadMessages to zero
          message.totalUnReadMessages = 0;
          message.readMessage = true;
        }
      });
      props.editDeal(dealID, { "UnreadMessages": unreadMessageList }, true);
    }
    else if (Object.keys(props.dealData).length > 0 && unreadMessageList?.length === 0 && messagesCountOfUser > 0) {
      props?.dealData?.UnreadMessages.filter((userData) => {
        if (userData.userId === loggedInUserId) {
          userData.totalUnReadMessages = 0;
          userData.readMessage = true;
        }
        return true;
      });
      props.editDeal(dealID, { "UnreadMessages": props?.dealData?.UnreadMessages }, true);
    }
  }


  // for send message to api
  const sendMessage = async () => {
    //for communication individual WITH transactionId between loggedInUser and selected user
    if (transaction?.transactionType === "Individual") {
      let blobPath = [];
      if (files.length > 0) {
        blobPath = files.map((file) => file.name);
        await uploadMultipleFiles("deal", `${dealID}`, files);
        setFiles([]);
      }
      const payload = {
        dealId: dealID,
        transactionId: transaction?.id,
        message: msg,
        transactionEquipment: props.equipment,
        from: userId,
        to: selectedUsers.map((p) => { return p.participant._id }),
        notificationType: "General Message",
        notes: "notes",
        askPrice: "$435",
        offerPrice: "$410",
        priceUnit: "USD",
        addedBy: userId,
        blobPath: blobPath,
        companyId: parentCompany.companyId,
      }
      const dealId = props?.dealCommunicationList[0]?.dealId;
      props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      // scrollToBottom();
      setMsg("");
      setBtnDisabled(true);
    }
    //for groups
    else if (transaction?.transactionType === "Group") {
      let blobPath = [];
      if (files.length > 0) {
        blobPath = files.map((file) => file.name);
        await uploadMultipleFiles("deal", `${dealID}`, files);
        setFiles([]);
      }
      const payload = {
        dealId: dealID,
        message: msg,
        transactionEquipment: props.equipment,
        from: userId,
        to: toGroupMembers,
        transactionId: transaction?.id,
        notificationType: "General Message",
        notes: "notes",
        askPrice: "$435",
        offerPrice: "$410",
        priceUnit: "USD",
        addedBy: userId,
        blobPath: blobPath,
      }
      const dealId = props?.dealCommunicationList[0]?.dealId;
      props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload, transaction);
      setMsg("");
      setBtnDisabled(true);
    }

  }
  const acceptMessage = async () => { // Make acceptMessage async
    const limit = parentCompany?.subscriptionData && parentCompany?.subscriptionData?.planType === 'BASIC'
      ? { users: 5, deals: 100 }
      : parentCompany?.subscriptionData && parentCompany.subscriptionData.planType === 'PROFESSIONAL'
        ? { users: 25, deals: 500 }
        : parentCompany?.subscriptionData && parentCompany?.subscriptionData?.planType === "ENTERPRISE"
          ? { users: 1000, deals: 1500 }
          : false;

    if (parentCompany?.subscriptionData && parentCompany?.subscriptionData?.subscriptionStatus) {
      let ids, completedDeals;

      // Define fetchUniqueFromIds as an async function and await its execution
      const fetchUniqueFromIds = async () => {
        if (Object.keys(parentCompany).length > 0 && data.length > 0) {
          ({ ids, completedDeals } = await uniqueFromIds(data, rest, parentCompany, getDealMessageListForPlan));
          if (Array.isArray(ids) && ids.length) {
            setExceedingDeals(ids);
          }
        }
      };

      // Await the fetchUniqueFromIds execution to complete before moving on
      await fetchUniqueFromIds();

      // Proceed only after fetchUniqueFromIds has completed
      if ((exceedingDeals.length <= limit.users && completedDeals <= limit.deals) ||
        (parentCompany?.subscriptionData?.planType === "ENTERPRISE")) {

        const transactioncommunication = {
          dealId: dealID,
          transactionId: transaction?.id,
          message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} accepted the offer`,
          transactionEquipment: props.equipment,
          from: userId,
          to: selectedUsers.map((p) => p.participant._id),
          notificationType: "Accept Offer",
          notes: "notes",
          askPrice: "$435",
          offerPrice: "$410",
          priceUnit: "USD",
          addedBy: userId,
          companyId: parentCompany.companyId,
        };

        const participantemail = {
          dealname: dealData?.dealName,
          dealparticipantemails: dealData?.dealParticipants
            ?.filter((un) => un.participant.acceptOffer === true)
            .map((p) => p.participant.email)
        };

        function updateUnreadMessages(masterObj, filterObj) {
          if (masterObj.userId === filterObj.participant._id) {
            masterObj.totalUnReadMessages += 1;
            masterObj.readMessage = false;
          }
        }

        const dealId = props?.dealCommunicationList[0]?.dealId;

        if (dealId && dealId?.UnreadMessages) {
          dealId?.UnreadMessages.forEach((masterObj) => {
            selectedUsers.forEach((filterObj) => {
              updateUnreadMessages(masterObj, filterObj);
            });
          });
        }

        const payload = { transactioncommunication, participantemail };
        props.submitAcceptMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      } else {
        if (loggedInUserData.user.isAdmin && loggedInUserData.user.isAdmin === true) {
          window.alert('You exceeded the limit for your plan. Please SUBSCRIBE to proceed further in the deal!');
          history.push('/mySubscription');
        } else {
          window.alert('Please contact the administrator');
        }
      }
    } else {
      if (loggedInUserData.user.isAdmin && loggedInUserData.user.isAdmin === true) {
        const res = window.confirm('Please SUBSCRIBE inorder to proceed in the deal!');
        if (res) {
          history.push('/mySubscription');
        }
      } else {
        window.alert('Please contact the administrator');
      }
    }
  };


  const rejectMessage = () => {
    const transactioncommunication = {
      dealId: dealID,
      transactionId: transaction?.id,
      message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} rejected an offer`,
      transactionEquipment: props.equipment,
      from: userId,
      to: selectedUsers.map((p) => { return p.participant._id }),
      notificationType: "Reject Offer",
      notes: "notes",
      askPrice: "$435",
      offerPrice: "$410",
      priceUnit: "USD",
      addedBy: userId,
      companyId: parentCompany.companyId,
    }
    const participantemail = {
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.rejectOffer === true).map((p) => { return p.participant.email })
    }
    const payload = { transactioncommunication, participantemail }
    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        selectedUsers.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    props.submitRejectMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
  }

  const handleEnterKeyChange = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      sendMessage();
      // scrollToBottom();
    }
  }
  const handleFileUpload = (event) => {
    if (selectedUsers?.length <= 0 && toGroupMembers.length === 0) {
      alert("You must select atleast one user or group to upload files.");
    } else {
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  // Helper function to format date WhatsApp style
  function formatWhatsAppDate(dateString) {
    // Check if dateString is valid
    if (!dateString) {
      return ''; // Handle invalid dates
    }

    const date = new Date(dateString);

    // If date is invalid, return a default value
    if (isNaN(date.getTime())) {
      return ''; // Handle invalid dates
    }
    const now = new Date();

    if (isToday(date)) {
      // Return time for today
      return 'Today'; // 'p' gives time like '2:35 PM'
    } else if (isYesterday(date)) {
      // Return 'Yesterday' for yesterday
      return 'Yesterday';
    } else if (isSameWeek(date, now, { weekStartsOn: 1 })) { // Week starts on Monday
      // Return the day of the week (e.g., 'Monday', 'Tuesday')
      return format(date, 'EEEE');
    } else {
      // Return date for older messages (e.g., 'Sep 15')
      return format(date, 'MMMM d, yyyy');
    }
  }

  // Function to group messages by week
  function groupMessagesByWeek(messages) {
    return messages.reduce((groups, message) => {
      const messageDate = new Date(message.createdAt);
      const groupKey = formatWhatsAppDate(message.createdAt);

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(message);

      return groups;
    }, {});
  }

  function extractTimeWithMeridian(dateString) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  }

  const ChatMessages = ({ filteredUserList, parentCompany, exceedingDeals }) => {
    const groupedMessages = groupMessagesByWeek(filteredUserList.length > 0 ? filteredUserList : []);
    return (
      <>
        {Object.entries(groupedMessages).map(([group, messages], index) => (
          <div key={index}>
            <Typography variant="subtitle2" align="center" sx={{ mt: 2, color: '#fbbc05' }}>
              <Chip label={group} sx={{ color: '#fff', backgroundColor: '#7393B3' }} />
            </Typography>
            {messages.map((chatMessagesDto, messageIndex) => (
              <React.Fragment key={messageIndex}>
                {(chatMessagesDto.from?._id !== userId) ?
                  <Grid container justifyContent="flex-start" spacing={1}>
                    <Grid item>
                      <Avatar alt="Venu" src={Eagle} />
                    </Grid>
                    <Grid item>
                      <Box sx={{ textAlign: "left", maxWidth: 'fit-content', marginTop: '3px' }}>
                        <Paper sx={{ p: 1, backgroundColor: "#f4efe3" }}>
                          <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                          <ListItemText
                            primary={`${chatMessagesDto?.from?.contact?.firstName[0]} ${chatMessagesDto?.from?.contact?.lastName[0]} (${chatMessagesDto.from?._id === dealData.seller ? "Seller" : "Buyer"}) : ${chatMessagesDto?.message}`}
                            secondary={extractTimeWithMeridian(chatMessagesDto.createdAt)}
                            sx={{
                              "& .MuiListItemText-primary": {
                                color: "#000", // Change this to the color you want for the primary text
                              },
                              "& .MuiListItemText-secondary": {
                                color: "gray", // Change this to the color you want for the secondary text
                              },
                            }}
                          />
                          {(dealData?.dealClosingSale?.find((un) => un.seller === loggedInUserData?.user?._id) && chatMessagesDto?.notificationType === "Make Offer" && !DealAcceptOffer && !DealRejectOffer) ?
                            <div className={classes.ButtonGroup}>
                              {makeAcceptButtonVisble ?
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <div className={classes.accept}>
                                      <Button variant="contained" onClick={acceptMessage} className={classes.AcceptButton}>Accept Offer</Button>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div className={classes.reject}>
                                      <Button variant="contained" onClick={(event) => { window.confirm("Are you sure want to reject offer?") && rejectMessage() }} className={classes.RejectButton}>Reject Offer</Button>
                                    </div>
                                  </Grid>
                                </Grid> : null}
                            </div> : null}
                          {(dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) && chatMessagesDto?.notificationType === "Accept Offer") ?
                            <div className={classes.ButtonGroup}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div className={classes.makePayment}>
                                    {makePaymentButtonVisble &&
                                      <Button
                                        variant="contained"
                                        onClick={handleClickOpen}
                                      >
                                        Make Payment
                                      </Button>
                                    }
                                  </div>
                                </Grid>
                              </Grid>
                            </div> : null}
                        </Paper>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Choose Any Payment Method
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'gray',
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <TabsContainer dealData={props.dealData} users={selectedUsers} setOpen={setOpen} transaction={transaction} parentCompany={parentCompany} />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose} className={classes.cancelButton}>Cancel</Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Grid>
                  </Grid>
                  :
                  <>
                    {(chatMessagesDto?.blobPath?.length === 0 && !Boolean(chatMessagesDto?.message)) ? //For no images and no messages
                      null :
                      <>
                        <Grid container justifyContent="flex-end" spacing={1}>
                          <Grid item>
                            <Box sx={{ textAlign: "left", maxWidth: 'fit-content', marginTop: '3px' }}>
                              <Paper sx={{ p: 1, backgroundColor: "#f4efe3" }}>
                                <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                                <ListItemText
                                  primary={chatMessagesDto.message}
                                  secondary={extractTimeWithMeridian(chatMessagesDto.createdAt)}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      color: "#000", // Change this to the color you want for the primary text
                                    },
                                    "& .MuiListItemText-secondary": {
                                      color: "gray", // Change this to the color you want for the secondary text
                                    },
                                  }}
                                />
                                {/* <Typography
                                  variant="caption"
                                  sx={{ display: "flex", mt: 0.5, justifyContent: 'flex-end', color: '#1A1E23' }}
                                >
                                  {extractTimeWithMeridian(chatMessagesDto.createdAt)}
                                </Typography> */}
                              </Paper>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Avatar alt="Venu" src={Eagle} />
                          </Grid>
                        </Grid>
                      </>
                    }
                  </>
                }
              </React.Fragment>
            ))}
          </div >
        ))}
      </>
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [ChatMessages]);

  //For getting the entiredealMessagelIst based on dealID, LoggedInUserData
  useEffect(() => {
    const getMessageList = async () => {
      try {
        if (dealID && rest) {
          await getDealMessageList(dealID, loggedInUserData);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getMessageList();
  }, [rest, getDealMessageList, dealID, loggedInUserData]);

  //For getting the transactionList based on dealID
  useEffect(() => {
    const getTransactionListByDealId = async () => {
      try {
        if (dealID && rest) {
          await getTransactionList(dealID); // Fetch the transaction list
        }
      } catch (e) {
        console.log(e);
      }
    };
    getTransactionListByDealId();
  }, [rest, getTransactionList, dealID]);

  //For filling the dealTransactionList when transactionList is retrieved
  useEffect(() => {
    if (transactionList?.length > 0) {
      setDealTransactionList(transactionList);
    }
  }, [transactionList, dealCommunicationList]);

  //For filling the DealMessageList when dealCommunicationList is retrieved
  useEffect(() => {
    if (dealCommunicationList?.length > 0) {
      setDealMessageList(dealCommunicationList);
    }
  }, [dealCommunicationList]);

  useEffect(() => {
    //for handling the real time messages
    const handleRealTimeMessages = async (message) => {
      if (message?.transactioncommunication?.transactionId?._id) {
        setDealMessageList((prevList) => [
          ...prevList,
          message?.transactioncommunication,
        ]);
      }
    };
    rest?.service('transactioncommunication').on('created', handleRealTimeMessages);
    return () => {
      rest?.service('transactioncommunication').removeListener('created', handleRealTimeMessages);
    };
  }, [rest]);


  useEffect(() => {
    //When transaction is created, we are updating it on time
    const handleRealTimeTransaction = async (message) => {
      if (message?.transaction?._id) {
        const transaction = {
          id: message.transaction._id,
          equipment: message.transaction.equipment,
          dealId: message.transaction.dealId,
          selectionName: message.transaction.selectionName,
          price: message.transaction.price,
          addedBy: message.transaction.addedBy,
          userGroup: message.transaction.userGroup,
          transactionType: message.transaction.transactionType,
          updatedAt: message.transaction.updatedAt,
        }
        setDealTransactionList((prevList) => [
          ...prevList,
          transaction
        ]);
      }
    };
    //for real-time edit transaction
    const handleRealTimeEditTransaction = async (message) => {
      if (message?.transaction?._id) {
        const transaction = {
          id: message.transaction._id,
          equipment: message.transaction.equipment,
          dealId: message.transaction.dealId,
          selectionName: message.transaction.selectionName,
          price: message.transaction.price,
          addedBy: message.transaction.addedBy,
          userGroup: message.transaction.userGroup,
          transactionType: message.transaction.transactionType,
          updatedAt: message.transaction.updatedAt,
        }

        // Check if the current user is still part of the transaction's user group
        const isUserInTransaction = transaction.userGroup.includes(loggedInUserData.user._id);

        // Check if the transaction already exists in the list
        const existingTransactionIndex = dealTransactionList.findIndex(t => t.id === transaction.id);
        let updatedDealTransactionList;

        if (existingTransactionIndex !== -1) {
          // If the transaction exists, update it
          updatedDealTransactionList = dealTransactionList.map((t) =>
            t.id === transaction.id ? transaction : t
          );

          // If the current user is removed from the group, filter out the transaction
          if (!isUserInTransaction) {
            updatedDealTransactionList = updatedDealTransactionList.filter(t => t.id !== transaction.id);
          }
        } else {
          // If the transaction is new and the user is part of the user group, add it to the list
          if (isUserInTransaction) {
            updatedDealTransactionList = [...dealTransactionList, transaction];
          } else {
            updatedDealTransactionList = [...dealTransactionList];
          }
        }
        if (message.transaction.addedBy !== loggedInUserData.user._id) {
          updatedDealTransactionList.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
        }

        setDealTransactionList(updatedDealTransactionList);

        // Update deal communication list based on the updated transaction list
        const updatedDealCommunicationList = dealCommunicationList.filter((com) => {
          const transactionId = com?.transactionId?._id;
          if (transactionId === undefined) {
            return false;
          }
          const transactionUserGroup = updatedDealTransactionList.find(t => t.id === transactionId)?.userGroup || [];
          return transactionUserGroup.includes(com.addedBy);
        });

        setDealMessageList(updatedDealCommunicationList);

        // If the user is currently viewing the transaction they were removed from, update the selectedTabId
        if (!isUserInTransaction && selectedTabId === transaction.id) {
          setSelectedTabId(null);
          setGroup(null);
          setTransaction({});
        }
      }
    };

    rest?.service('transaction').on('created', handleRealTimeTransaction);
    rest?.service('transaction').on('patched', handleRealTimeEditTransaction);
    return () => {
      rest?.service('transaction').removeListener('created', handleRealTimeTransaction);
      rest?.service('transaction').removeListener('patched', handleRealTimeEditTransaction);
    };
  }, [rest, dealTransactionList, loggedInUserData, transaction, dealCommunicationList, selectedTabId]);


  // const filterMessages = (messages, participantIds) => {
  //   return messages.filter(communication =>
  //     participantIds.includes(communication.from._id) ||
  //     communication.to.some(to => participantIds.includes(to._id))
  //   );
  // };
  //To update the messages for selected tabs
  useEffect(() => {
    const filteredList = dealMessageList.filter((com) => {
      if (com?.transactionId?._id === undefined) {
        return false;
      }
      // Check if transactionId matches
      const isMatch = com?.transactionId?._id === transaction?.id;
      if (!isMatch) return false;

      // Check if from or to contains the logged in user's id
      const isUserInFrom = com.from?._id === loggedInUserData.user._id;
      const isUserInTo = com.to?.some((recipient) => recipient._id === loggedInUserData.user._id);
      return isUserInFrom || isUserInTo;
    });
    const isPaymentDone = filteredList.filter((transactioncom) => transactioncom.notificationType === "Payment");
    const isAcceptDone = filteredList.filter((transactioncom) => transactioncom.notificationType === "Accept Offer");
    if (isPaymentDone.length) {
      setMakePaymentButtonVisble(false);
    } else if (isAcceptDone.length) {
      setMakeAcceptButtonVisble(false);
    } else {
      setMakePaymentButtonVisble(true);
      setMakeAcceptButtonVisble(true);
    }
    setFilterUserList(filteredList);
  }, [selectedUsers, transaction, dealMessageList, dealCommunicationList, loggedInUserData]);

  const handleUnselect = (event) => {
    setValue([]);
    setGroup(null);
    setNameOfSelectedContact('');
  }

  useEffect(() => {
    if (rest) {
      const getCompany = async () => {
        if (loggedInUserData?.user?.parentCompany?._id) {
          await getCompanyById(loggedInUserData?.user?.parentCompany?._id);
        } else if (typeof loggedInUserData?.user?.parentCompany === 'string') {
          await getCompanyById(loggedInUserData?.user?.parentCompany);
        }
      }
      getCompany();
    }
  }, [getCompanyById, loggedInUserData, rest]);

  useEffect(() => {
    if (Object.keys(companyData).length > 0) {
      setParentCompany(companyData);
    }
  }, [companyData])
  // const uniqueFromIds = (data, rest, parentCompany, getDealMessageListForPlan) => {
  //   let ids = [];

  //   if (Array.isArray(data) && data.length > 0 && rest && parentCompany?.subscriptionData?.planType !== "ENTERPRISE") {
  //     data.forEach(async (deal) => {
  //       const transactionCommunication = await getDealMessageListForPlan(deal.id);

  //       (transactionCommunication || [])
  //         .filter(communication =>
  //           deal.id === communication?.dealId?._id && communication?.notificationType === 'Make Offer'
  //         )
  //         .forEach(communication => {
  //           if (!ids.includes(communication?.from?._id)) {
  //             ids.push(communication?.from?._id);
  //           }
  //         });
  //     });
  //   }
  //   return ids;
  // };


  return (
    <>
      {/* <Grid container spacing={1} sx={{ padding: '10px 2px' }}>
        <Grid item xs={12} lg={4} sm={12}>
          <Link to="/dealpage">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              text-decoration="none"
            >
              Back To Deals
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4} sm={12}>
          <Typography variant="h6" style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}>
            {dealData && dealData.dealName}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} sm={12}>
          <Typography variant="h6" style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}>
            {"Role: " + usertype}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          background: "#607d8b",
          color: '#fff',
          paddingLeft: "40px",
          padding: "8px 16px",
        }}
      >
        <Typography variant="h6">Deal Communication</Typography>
      </Box> */}
      {/* <Grid container sx={{ height: 'calc(100vh)' }}>
        {(nameOfSelectedContact && isSmallScreen) ? null :
          <Grid
            item
            as={BorderRight500}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              height: "100%",  // Ensure the item takes the full height
            }}
          >
            <UserList
              loggedInUserData={loggedInUserData}
              sortedUserList={props?.users}
              setSelectedUsers={setSelectedUsers}
              setTransaction={setTransaction}
              dealID={dealID}
              equipment={props?.equipment}
              GenerateTransactionRegisterForm={GenerateTransactionRegisterForm}
              dealTransactionList={dealTransactionList.length > 0 && dealTransactionList}
              setToGroupMembers={setToGroupMembers}
              submitDealMessageWithoutTransactionId={submitDealMessageWithoutTransactionId}
              editTransaction={editTransaction}
              setSelectedTabId={setSelectedTabId}
              selectedTabId={selectedTabId}
              group={group}
              setGroup={setGroup}
              setNameOfSelectedContact={setNameOfSelectedContact}
              isSmallScreen={isSmallScreen}
              value={value}
              setValue={setValue}
            />
          </Grid>}
        {(nameOfSelectedContact) && (
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              flex: 1, // Add this line
            }}
          >
            <AppBar
              position="sticky"
              sx={{
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                color: "black",
              }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="primary"
                  sx={{ mr: 2 }}
                  onClick={handleUnselect}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div">
                  {nameOfSelectedContact}
                </Typography>
              </Toolbar>
            </AppBar>
            <Box
              sx={{// Makes this grid item grow to fill the available space
                flex: 1, // Takes up remaining space
                width: "100%",
                overflowY: "auto", // Enables scrolling for ChatMessages
                position: "relative",
                '&::-webkit-scrollbar': { display: 'none' },  // Hide scrollbar in Webkit browsers
                '-ms-overflow-style': 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',
              }}
              ref={messagesEndRef}
            >
              {filteredUserList ? ChatMessages : ""}
            </Box>
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1,
                boxShadow: "0 -1px 5px rgba(0,0,0,0.1)", // Add a slight shadow for visual separation
                padding: "8px 16px",
              }}
            >
              <TextField
                fullWidth
                multiline
                variant="outlined"
                placeholder="Enter your text here..."
                value={msg}
                onChange={handleMessageChange}
                onKeyDown={handleEnterKeyChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <input
                        accept="*"
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        multiple
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="file-upload">
                        <IconButton component="span">
                          <AttachFileIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={btnDisabled && files.length === 0} onClick={sendMessage}>
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {files.length > 0 && (
                <Box mt={2}>
                  <Slider {...settings}>
                    {files.map((file, index) => (
                      <Stack key={index}>
                        <Chip
                          label={file.name}
                          onDelete={() => handleFileDelete(file.name)}
                          deleteIcon={<CancelOutlinedIcon />}
                          style={{ marginTop: 8 }}
                        />
                      </Stack>
                    ))}
                  </Slider>
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid> */}
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
          <Box>
            <Link to="/dealpage">
              <Tooltip title={"Back To Deals"} arrow>
                <IconButton>
                  <ArrowBackIcon style={{ color: 'rgb(120, 120, 120)' }} />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>

          <Box>
            <Typography
              variant="h6"
              style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}
            >
              {dealData && dealData.dealName}
            </Typography>
          </Box>

          <Box textAlign="right">
            <Typography
              variant="h6"
              style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}
            >
              {i18n.translate("role_display", { "role": usertype })}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#607d8b",
            paddingLeft: "40px",
            padding: "8px 16px",
          }}
        >
          <Typography variant="h6" sx={{ color: 'white' }}>{i18n.translate("deal_communication")}</Typography>
        </Box>

        <Box display="flex" flex="1" overflow="hidden" sx={{ height: '100%' }}>
          {!nameOfSelectedContact || !isSmallScreen ? (
            <Box
              display="flex"
              flexDirection="column"
              sx={isSmallScreen ? { overflowY: 'auto', height: '100%', width: '100%' } : { overflowY: 'auto', height: '100%' }}
              borderRight="1px solid #ccc" // Optional border to replace BorderRight500
            >
              <UserList
                loggedInUserData={loggedInUserData}
                sortedUserList={props?.users}
                setSelectedUsers={setSelectedUsers}
                setTransaction={setTransaction}
                dealID={dealID}
                equipment={props?.equipment}
                GenerateTransactionRegisterForm={GenerateTransactionRegisterForm}
                dealTransactionList={dealTransactionList.length > 0 && dealTransactionList}
                setToGroupMembers={setToGroupMembers}
                submitDealMessageWithoutTransactionId={submitDealMessageWithoutTransactionId}
                editTransaction={editTransaction}
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                group={group}
                setGroup={setGroup}
                setNameOfSelectedContact={setNameOfSelectedContact}
                isSmallScreen={isSmallScreen}
                value={value}
                setValue={setValue}
                dealMessageList={dealMessageList}
                locale={locale}
                parentCompany={parentCompany}
              />
            </Box>
          ) : null}

          {nameOfSelectedContact && (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              height="100%"
            >
              <AppBar
                position="sticky"
                sx={{
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={handleUnselect}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h6" component="div">
                    {nameOfSelectedContact}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  flex: 1,
                  width: "100%",
                  overflowY: "auto",
                  position: "relative",
                  '&::-webkit-scrollbar': { display: 'none' },
                  '-ms-overflow-style': 'none',
                  scrollbarWidth: 'none',
                  backgroundColor: '#E5E4E2'
                }}
                ref={messagesEndRef}
              >
                {filteredUserList ? <ChatMessages filteredUserList={filteredUserList} parentCompany={parentCompany} exceedingDeals={exceedingDeals} /> : ""}
              </Box>

              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                  boxShadow: "0 -1px 5px rgba(0,0,0,0.1)",
                  padding: "8px 16px",
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  placeholder={i18n.translate("enter_text_placeholder")}
                  value={msg}
                  onChange={handleMessageChange}
                  onKeyDown={handleEnterKeyChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <input
                          accept="*"
                          style={{ display: "none" }}
                          id="file-upload"
                          type="file"
                          multiple
                          onChange={handleFileUpload}
                        />
                        <label htmlFor="file-upload">
                          <IconButton component="span">
                            <AttachFileIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={btnDisabled && files.length === 0} onClick={sendMessage}>
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {files.length > 0 && (
                  <Box mt={2}>
                    <Slider {...settings}>
                      {files.map((file, index) => (
                        <Stack key={index}>
                          <Chip
                            label={file.name}
                            onDelete={() => handleFileDelete(file.name)}
                            deleteIcon={<CancelOutlinedIcon />}
                            style={{ marginTop: 8 }}
                          />
                        </Stack>
                      ))}
                    </Slider>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
  deal: state.deal && state.deal.dealList,
  dealData: state.deal && state.deal.dealData,
  user: state.user.userData,
  loggedInUserData: state.user && state.user.loggedInUserData,
  transactionList: state.transaction && state.transaction.transactionList,
  locale: state.locale,
  companyData: state.company.companyData,
});

export default connect(mapStateToProps, { getDealMessageList, getDealMessageListForPlan, getCompanyById, submitDealMessage, submitAcceptMessage, submitRejectMessage, editDeal, editTransactionBlobs, getTransactionList, submitDealMessageWithoutTransactionId, GenerateTransactionRegisterForm, editTransaction })(DealMessageChat);